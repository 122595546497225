import { ClipboardList, Building, Users, Plus } from 'lucide-react';
import { ViewType } from '../pages/Dashboard';

const SetupStep = ({
  number,
  icon: Icon,
  title,
  description,
  onClick,
  buttonText,
}: {
  number: number;
  icon: React.ElementType;
  title: string;
  description: string;
  onClick: () => void;
  buttonText: string;
}) => (
  <div className="flex items-start space-x-4 p-6 bg-white rounded-lg shadow-sm">
    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
      <span className="text-blue-600 font-medium">{number}</span>
    </div>
    <div className="flex-grow space-y-4">
      <div className="space-y-1">
        <div className="flex items-center space-x-2">
          <Icon className="w-5 h-5 text-blue-600" />
          <h3 className="font-medium text-gray-900">{title}</h3>
        </div>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
      <button
        onClick={onClick}
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 transition-colors"
      >
        {buttonText}
      </button>
    </div>
  </div>
);

const EmptyProcessList = ({ onViewChange }: { onViewChange: (view: ViewType) => void }) => {
  return (
    <div className="max-w-2xl mx-auto mt-8 p-2 lg:p-0 space-y-6">
      <div className="text-center">
        <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
          <ClipboardList className="w-8 h-8 text-blue-600" />
        </div>
        <h2 className="text-2xl font-semibold text-gray-900">Willkommen bei BikeCheck Pro</h2>
        <p className="mt-2 text-gray-600">Folgen Sie diesen Schritten, um loszulegen</p>
      </div>

      <div className="space-y-4">
        <SetupStep
          number={1}
          icon={Building}
          title="Werkstatt einrichten"
          description="Hinterlegen Sie Ihre Werkstattdaten für professionelle Gutachten und Kostenvoranschläge"
          onClick={() => onViewChange('workshop')}
          buttonText="Werkstatt konfigurieren"
        />

        <SetupStep
          number={2}
          icon={Users}
          title="Team einladen"
          description="Laden Sie Ihre Mitarbeiter ein und weisen Sie ihnen die passenden Rollen zu"
          onClick={() => onViewChange('team')}
          buttonText="Team verwalten"
        />

        <SetupStep
          number={3}
          icon={Plus}
          title="Ersten Vorgang erstellen"
          description="Erstellen Sie Ihr erstes Gutachten und erleben Sie den optimierten Workflow"
          onClick={() => onViewChange('create')}
          buttonText="Vorgang erstellen"
        />
      </div>
    </div>
  );
};

export default EmptyProcessList;
