import React, { useState, useEffect, useRef } from 'react';
import { useProcessStore } from '../../../stores/processStore';
import { BikeDetails } from '../components/BikeDetails';
import QuoteManagement from '../components/QuoteManagement';
import { AddDamageForm } from '../components/AddDamageForm';
import DamageList from '../components/DamageList';
import { getStatusStyle, getStatusText } from '../../../utils/processHelpers';
import { CheckIcon, Plus, Bike, ChevronDown, FileText, Wrench, Paperclip, LucideIcon, Loader2 } from 'lucide-react';
import { AssigneeSelector } from '../components/AssigneeSelector';
import PDFGeneratorButton from '../../Export/ExportPDF';

interface MobileViewProps {
  onBack: () => void;
}

interface CollapsibleSectionProps {
  title: string;
  icon: LucideIcon;
  children: React.ReactNode;
  defaultOpen?: boolean;
}

const STATUS_OPTIONS = [
  { value: 1, label: 'Neu' },
  { value: 2, label: 'Wird bearbeitet' },
  { value: 3, label: 'Wird geprüft' },
  { value: 4, label: 'Versendet' },
  { value: 5, label: 'Abgeschlossen' },
];

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  icon: Icon,
  children,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <section className="border-t border-gray-100">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full py-4 px-1 text-left group hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-center gap-3">
          <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-blue-50 text-blue-600 group-hover:bg-blue-100 transition-colors">
            <Icon className="w-4 h-4" />
          </div>
          <h2 className="text-lg font-medium text-gray-900">{title}</h2>
        </div>
        <ChevronDown
          className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''
            }`}
        />
      </button>
      {isOpen && <div className="pb-4 px-1">{children}</div>}
    </section>
  );
};

export const MobileView: React.FC<MobileViewProps> = ({ onBack }) => {
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showAddDamageForm, setShowAddDamageForm] = useState(false);
  const statusDropdownRef = useRef<HTMLDivElement>(null);

  const { currentProcess, isDamagesLoading, damages, updateProcessAssignee, updateProcessStatus } = useProcessStore();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target as Node)) {
        setShowStatusDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!currentProcess) return null;

  const handleStatusUpdate = async (newStatus: number) => {
    try {
      await updateProcessStatus(currentProcess.$id, newStatus);
      setShowStatusDropdown(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="grid grid-rows-[auto_1fr_auto] h-full bg-gray-50">
      {/* Enhanced Header */}
      <div className="bg-white border-b border-gray-200 px-4 pt-3 pb-4 shadow-sm">
        <div className="flex items-center justify-between gap-3">
          <div className="relative flex-shrink-0" ref={statusDropdownRef}>
            <button
              onClick={() => setShowStatusDropdown(!showStatusDropdown)}
              className={`relative inline-flex items-center px-4 py-2 rounded-lg border shadow-sm text-sm font-medium transition-all
                ${showStatusDropdown ? 'border-blue-500 ring-2 ring-blue-100' : 'border-gray-200 hover:border-gray-300'}
                ${getStatusStyle(currentProcess.status)}`}
            >
              <span>{getStatusText(currentProcess.status)}</span>
              <ChevronDown className="w-4 h-4 ml-2 opacity-70" />
            </button>

            {showStatusDropdown && (
              <div className="absolute z-10 mt-2 w-52 bg-white rounded-lg shadow-lg border border-gray-200">
                <ul className="py-0">
                  {STATUS_OPTIONS.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleStatusUpdate(option.value)}
                      className="cursor-pointer select-none relative my-1 px-4 py-2 hover:bg-gray-50 transition-colors"
                    >
                      <span className={`block truncate px-3 py-1 rounded ${getStatusStyle(option.value)}`}>
                        {option.label}
                      </span>
                      {currentProcess.status === option.value && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                          <CheckIcon className="w-4 h-4 text-blue-600" />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="flex-grow">
            <AssigneeSelector
              processId={currentProcess.$id}
              currentAssigneeId={currentProcess.assignedUserId}
              onAssigneeChange={(userId) => updateProcessAssignee(currentProcess.$id, userId)}
            />
          </div>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="overflow-y-auto">
        <div className="divide-y divide-gray-100">
          {/* Damages Section */}
          <section className="p-4 bg-white">
            <div className="mb-4">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <div className="p-1.5 bg-blue-50 rounded-lg">
                    <Wrench className="w-5 h-5 text-blue-600" />
                  </div>
                  <h2 className="text-lg font-semibold text-gray-900">
                    {(showAddDamageForm || damages.length === 0) ? 'Neuer Schaden' : 'Schadensliste'}
                  </h2>
                </div>
                {damages.length > 0 && !showAddDamageForm && (
                  <button
                    onClick={() => setShowAddDamageForm(true)}
                    className="p-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors shadow-sm hover:shadow-md"
                    aria-label="Neuer Schaden hinzufügen"
                  >
                    <Plus className="w-5 h-5" />
                  </button>
                )}
              </div>
              {damages.length > 0 && !showAddDamageForm && (
                <p className="text-sm text-gray-600">
                  {damages.length} {damages.length === 1 ? 'Schaden' : 'Schäden'} dokumentiert
                </p>
              )}
            </div>

            {isDamagesLoading ? (
              <div className="flex justify-center items-center h-32">
                <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
              </div>
            ) : showAddDamageForm || damages.length === 0 ? (
              <AddDamageForm
                processId={currentProcess.$id}
                onSuccess={() => setShowAddDamageForm(false)}
                onCancel={() => setShowAddDamageForm(false)}
              />
            ) : (
              <DamageList />
            )}
          </section>

          {/* Collapsible Sections with Icons */}
          <div className="px-4 bg-white">
            <CollapsibleSection title="Fahrraddetails" icon={Bike}>
              <BikeDetails
                processId={currentProcess.$id}
                quoteFileId={currentProcess.quoteFileId}
              />
            </CollapsibleSection>

            <CollapsibleSection title="Anhänge" icon={Paperclip}>
              <QuoteManagement
                processId={currentProcess.$id}
                quoteFileId={currentProcess.quoteFileId}
                quoteName={currentProcess.quoteName}
              />
            </CollapsibleSection>
          </div>
        </div>
        {damages.length > 0 && (
          <div className="my-8 flex justify-center">
            <PDFGeneratorButton
              process={currentProcess}
              damages={damages}
              className="text-sm px-4 py-2 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition-colors"
            />
          </div>
        )}
      </div>

      {/* Enhanced Footer */}
      <div className="border-t border-gray-200 bg-white px-4 py-3 flex justify-center items-center gap-2">
        <Bike className="text-gray-400 w-5 h-5" />
        <span className="text-sm text-gray-500 font-medium">BikeCheck Pro</span>
      </div>
    </div>
  );
};

export default MobileView;
