import { useState, useEffect } from 'react';
import { Functions, Databases, Models } from 'appwrite';
import { client } from '../../utils/appwrite';
import { ClipboardList, CreditCard, Timer } from 'lucide-react';
import { SubscriptionPlans } from '../SubscriptionPlans';

interface SubscriptionDocument extends Models.Document {
  workshopId: string;
  stripeCustomerId: string;
  stripePriceId: string;
  subscriptionStatus: string;
  currentPeriodEnd: string;
  plan: string;
}

interface SubscriptionSettingsProps {
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
}

export const SubscriptionSettings: React.FC<SubscriptionSettingsProps> = ({
  onError,
  onSuccess,
}) => {
  const [subscription, setSubscription] = useState<SubscriptionDocument | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(true);

  const functions = new Functions(client);
  const databases = new Databases(client);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await databases.listDocuments<SubscriptionDocument>(
          '67197d230036564bfc99',
          'subscriptions',
          [
            // We'll add the workshop query here once we get the workshopId
          ]
        );

        if (response.documents.length > 0) {
          setSubscription(response.documents[0]);
        }
      } catch (error) {
        console.error('Error fetching subscription:', error);
        onError('Fehler beim Laden des Abonnements');
      } finally {
        setLoadingSubscription(false);
      }
    };

    fetchSubscription();
  }, []);

  const handleSelectPlan = (planName: string) => {
    if (planName === 'enterprise') {
      window.location.href = 'mailto:contact@bikecheck-pro.de';
      return;
    }
    //   handleCreateCheckoutSession(planName);
  };

  const handleCreateCheckoutSession = async () => {
    setLoading(true);
    try {
      const response = await functions.createExecution(
        'your-subscription-function-id',
        JSON.stringify({
          action: 'createCheckoutSession',
        })
      );

      const result = JSON.parse(response.responseBody);
      if (result.url) {
        window.location.href = result.url;
      }
    } catch (error) {
      console.error('Checkout error:', error);
      onError('Fehler beim Erstellen der Checkout-Session');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!confirm('Möchten Sie das Abonnement wirklich kündigen?')) return;

    setLoading(true);
    try {
      const response = await functions.createExecution(
        'your-subscription-function-id',
        JSON.stringify({
          action: 'cancelSubscription',
        })
      );

      const result = JSON.parse(response.responseBody);
      if (result.success) {
        onSuccess('Abonnement wurde erfolgreich gekündigt');
        // Refresh subscription data
        // You might want to implement this
      }
    } catch (error) {
      console.error('Cancel subscription error:', error);
      onError('Fehler beim Kündigen des Abonnements');
    } finally {
      setLoading(false);
    }
  };

  if (loadingSubscription) {
    return <div className="text-center py-4">Laden...</div>;
  }

  const formatDate = (dateStr?: string) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toLocaleDateString('de-DE', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  const getPlanName = (plan?: string) => {
    switch (plan) {
      case 'hobby':
        return 'Hobby';
      case 'professional':
        return 'Professional';
      case 'workshop':
        return 'Workshop';
      case 'enterprise':
        return 'Enterprise';
      default:
        return '';
    }
  };

  if (loadingSubscription) {
    return <div className="text-center py-4">Laden...</div>;
  }

  return (
    <div className="space-y-6">
      {subscription && (
        <div
          className={`border rounded-lg p-4 flex items-center gap-2 ${
            subscription.subscriptionStatus === 'trial'
              ? 'bg-blue-50 border-blue-200 text-blue-700'
              : 'bg-white border-gray-200 text-gray-700'
          }`}
        >
          {subscription.subscriptionStatus === 'trial' ? (
            <>
              <Timer className="h-5 w-5 flex-shrink-0" />
              <span>
                Ihre Testphase endet am{' '}
                {formatDate(subscription.currentPeriodEnd)}
              </span>
            </>
          ) : (
            <>
              <ClipboardList className="h-5 w-5 flex-shrink-0" />
              <div className="flex flex-col sm:flex-row sm:items-center sm:gap-8">
                <span>
                  <strong>Plan:</strong> {getPlanName(subscription.plan)}
                </span>
                {subscription.currentPeriodEnd && (
                  <span>
                    <strong>Vertragsverlängerung:</strong>{' '}
                    {formatDate(subscription.currentPeriodEnd)}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      )}

      <SubscriptionPlans
        onSelectPlan={handleSelectPlan}
        currentPlan={subscription?.plan}
        loading={loading}
      />
    </div>
  );
};
