import { useState, useEffect } from 'react';
import { Account, Functions, Teams } from 'appwrite';
import { client } from '../utils/appwrite';
import { AlertCircle, UserPlus, Trash2, Users } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

interface TeamMember {
  $id: string;
  email: string;
  name?: string;
  roles: string[];
}

type Role = 'owner' | 'admin' | 'mechanic';

const TeamManagement = () => {
  const { teamId } = useAuth();
  const [members, setMembers] = useState<TeamMember[]>([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState<Role>('mechanic');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const functions = new Functions(client);
  const teams = new Teams(client);

  const loadMembers = async () => {
    if (!teamId) return;

    try {
      const memberships = await teams.listMemberships(teamId);
      setMembers(
        memberships.memberships.map((member) => ({
          $id: member.$id,
          email: member.userEmail,
          name: member.userName,
          roles: member.roles,
        }))
      );
    } catch (error) {
      console.error('Failed to load team members:', error);
      setError('Teammitglieder konnten nicht geladen werden');
    }
  };

  useEffect(() => {
    loadMembers();
  }, [teamId]);

  const handleAddMember = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!teamId) return;

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await functions.createExecution(
        '67240e7e0025a85c3420',
        JSON.stringify({
          action: 'add',
          email,
          name,
          teamId,
          role,
        })
      );

      const result = JSON.parse(response.responseBody) as {
        success: boolean;
        message?: string;
      };

      if (result.success) {
        setSuccess('Mitglied wurde erfolgreich hinzugefügt');
        setEmail('');
        setName('');
        setRole('mechanic');
        await loadMembers();
      } else {
        setError(result.message || 'Mitglied konnte nicht hinzugefügt werden');
      }
    } catch (error) {
      console.error('Error adding member:', error);
      setError('Mitglied konnte nicht hinzugefügt werden');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMember = async (memberId: string) => {
    if (!teamId) return;

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await teams.deleteMembership(teamId, memberId);
      setSuccess('Teammitglied wurde erfolgreich entfernt');
      await loadMembers();
    } catch (error) {
      console.error('Error removing member:', error);
      setError('Teammitglied konnte nicht entfernt werden');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex items-center space-x-2 mb-6">
        <Users className="h-6 w-6 text-blue-600" />
        <h1 className="text-2xl font-bold">Teamverwaltung</h1>
      </div>

      {error && (
        <div className="flex items-center gap-2 p-4 mb-4 text-red-700 bg-red-50 rounded-md">
          <AlertCircle className="h-5 w-5 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      {success && (
        <div className="p-4 mb-4 text-green-700 bg-green-50 rounded-md">
          <p>{success}</p>
        </div>
      )}

      <div className="bg-white p-6 rounded-lg shadow mb-6">
        <h2 className="text-lg font-semibold mb-4">Teammitglied hinzufügen</h2>
        <form onSubmit={handleAddMember} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border rounded-md"
              placeholder="Max Mustermann"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              E-Mail Adresse
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border rounded-md"
              placeholder="email@beispiel.de"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Rolle
            </label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value as Role)}
              className="w-full p-2 border rounded-md bg-white"
              required
            >
              <option value="mechanic">Mechaniker</option>
              <option value="admin">Administrator</option>
              <option value="owner">Inhaber</option>
            </select>
          </div>

          <button
            type="submit"
            disabled={loading}
            className="flex items-center justify-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            <UserPlus className="h-4 w-4" />
            <span>
              {loading ? 'Wird hinzugefügt...' : 'Mitglied hinzufügen'}
            </span>
          </button>
        </form>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">Teammitglieder</h2>
        <div className="space-y-4">
          {members.length === 0 ? (
            <p className="text-gray-500 text-center py-4">
              Keine Teammitglieder vorhanden
            </p>
          ) : (
            members.map((member) => (
              <div
                key={member.$id}
                className="flex items-center justify-between p-4 border rounded-lg"
              >
                <div>
                  <p className="font-medium">{member.name || member.email}</p>
                  {member.name && (
                    <p className="text-sm text-gray-500">{member.email}</p>
                  )}
                  <p className="text-xs text-gray-500 mt-1">
                    Rolle: {member.roles.join(', ')}
                  </p>
                </div>
                <button
                  onClick={() => handleRemoveMember(member.$id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-md"
                  disabled={loading}
                  title="Teammitglied entfernen"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamManagement;
