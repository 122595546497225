import React from 'react';
import Modal from './Modal';

interface DeleteConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  cancelText?: string;
  confirmText?: string;
}

const DeleteConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  cancelText = 'Abbrechen',
  confirmText = 'Löschen',
}: DeleteConfirmModalProps) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const actions = (
    <>
      <button
        onClick={onClose}
        className="px-4 py-2 rounded text-gray-600 hover:bg-gray-100"
      >
        {cancelText}
      </button>
      <button
        onClick={handleConfirm}
        className="px-4 py-2 rounded bg-red-600 text-white hover:bg-red-700"
      >
        {confirmText}
      </button>
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} actions={actions}>
      {message}
    </Modal>
  );
};

export default DeleteConfirmModal;
