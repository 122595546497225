import React from 'react';
import { Loader2 } from 'lucide-react';

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-white">
      {/* Logo with subtle bounce animation */}
      <div className="animate-bounce-gentle mb-8">
        <img src="/logo192.png" alt="Logo" className="w-24 h-24" />
      </div>

      {/* Spinner with pulsing shadow */}
      <div className="relative">
        <div className="absolute -inset-1 bg-blue-500/20 rounded-full blur animate-pulse" />
        <Loader2
          className="w-8 h-8 text-blue-500 animate-spin"
          strokeWidth={3}
        />
      </div>
    </div>
  );
};

// Add custom animation to Tailwind
const style = document.createElement('style');
style.textContent = `
  @keyframes bounce-gentle {
    0%, 100% {
      transform: translateY(-5%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  .animate-bounce-gentle {
    animation: bounce-gentle 2s infinite;
  }
`;
document.head.appendChild(style);

export default LoadingScreen;
