// components/Sidebar.tsx
import React, { useState } from 'react';
import {
  ListTodo,
  PlusCircle,
  UserCircle,
  Menu,
  LogOut,
  ChevronLeft,
  Building2,
  Users,
  Settings,
  MessageSquarePlus,
  HelpCircle,
} from 'lucide-react';
import { ViewType } from '../pages/Dashboard';
import { useHeaderStore } from '../stores/headerStore';
import FeedbackButton from './FeedbackButton';

interface SidebarProps {
  username: string | undefined;
  teamRole: string | null;
  onViewChange: (view: ViewType) => void;
  activeView: ViewType;
  onSignOut: () => void;
  onBack: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  username,
  teamRole,
  onViewChange,
  activeView,
  onSignOut,
  onBack,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const { title, subtitle, actions, showBack, breadcrumbs, loading } = useHeaderStore();

  const handleViewClick = (view: ViewType) => {
    onViewChange(view);
    setIsOpen(false);
  };

  // Navigation items component - used in both mobile and desktop
  const NavigationItems = () => (
    <nav className="space-y-1">
      <button
        onClick={() => handleViewClick('create')}
        className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'create' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50 text-gray-700'}`}
      >
        <PlusCircle className="h-5 w-5" />
        <span>Neuer Vorgang</span>
      </button>
      <button
        onClick={() => handleViewClick('list')}
        className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'list' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50 text-gray-700'}`}
      >
        <ListTodo className="h-5 w-5" />
        <span>Vorgänge</span>
      </button>
      <button
        onClick={() => handleViewClick('workshop')}
        className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'workshop' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50 text-gray-700'}`}
      >
        <Building2 className="h-5 w-5" />
        <span>Werkstatt</span>
      </button>
      {teamRole === 'admin' && (
        <button
          onClick={() => handleViewClick('team')}
          className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'team' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50 text-gray-700'}`}
        >
          <Users className="h-5 w-5" />
          <span>Team verwalten</span>
        </button>
      )}
    </nav>
  );

  // Footer buttons component
  const FooterButtons = () => (
    <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-gray-200">
      <button
        onClick={() => handleViewClick('help')}
        className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'help' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50 text-gray-700'
          }`}
      >
        <HelpCircle className="h-5 w-5" />
        <span>Hilfe & Support</span>
      </button>
      <button
        onClick={() => setIsFeedbackOpen(true)}
        className="flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md text-gray-700 hover:bg-gray-50"
      >
        <MessageSquarePlus className="h-5 w-5" />
        <span>Feedback</span>
      </button>
      <button
        onClick={() => handleViewClick('settings')}
        className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'settings' ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-50 text-gray-700'}`}
      >
        <Settings className="h-5 w-5" />
        <span>Einstellungen</span>
      </button>
      <button
        onClick={onSignOut}
        className="flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md text-gray-700 hover:bg-gray-50"
      >
        <LogOut className="h-5 w-5" />
        <span>Ausloggen</span>
      </button>
    </div>
  );

  return (
    <>
      {/* Mobile Header */}
      <header className="lg:hidden fixed top-0 left-0 right-0 h-16 bg-white border-b border-gray-200 flex items-center justify-between px-4 z-20">
        <div className="flex items-center space-x-2">
          {showBack ? (
            <button
              onClick={onBack}
              className="p-2 rounded-md text-gray-600 hover:bg-gray-100"
              aria-label="Go back"
            >
              <ChevronLeft className="h-6 w-6" />
            </button>
          ) : (
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 rounded-md text-gray-600 hover:bg-gray-100"
              aria-label="Toggle menu"
            >
              {isOpen ? <ChevronLeft className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          )}

          {!title ? (
            <div className="flex items-center">
              <img src="logo.png" alt="BikeCheck Logo" className="h-9 w-auto" />

              <h2 className="text-xl font-semibold ml-2">
                <span className="text-gray-800">Bike</span>
                <span className="text-blue-600">Check</span>
                <span className="text-gray-800"> Pro</span>
              </h2>

            </div>
          ) : (
            <div className="flex items-center">
              {loading ? (
                <div className="animate-pulse">
                  <div className="h-5 w-32 bg-gray-200 rounded"></div>
                  {subtitle && <div className="h-4 w-24 bg-gray-200 rounded mt-1"></div>}
                </div>
              ) : (
                <>
                  <div className="flex items-center space-x-3">
                    <img src="logo.png" alt="BikeCheck Logo" className="h-9 w-auto" />
                    <div>
                      <h1 className="text-lg font-semibold text-gray-900">{title}</h1>
                      {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
                    </div>
                  </div>
                  {breadcrumbs.length > 0 && (
                    <div className="flex items-center space-x-2 text-sm text-gray-500 mt-2">
                      {/* Breadcrumbs code remains the same */}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {actions.length > 0 && (
          <div className="flex items-center space-x-2">
            {actions.map((action, index) => (
              <button
                key={index}
                onClick={action.onClick}
                className="p-2 rounded-md text-gray-600 hover:bg-gray-100"
                aria-label={action.label}
              >
                {action.icon}
              </button>
            ))}
          </div>
        )}
      </header>

      {/* Mobile Menu Overlay */}
      {isOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsOpen(false)}
          aria-label="Close menu overlay"
        />
      )}

      {/* Sidebar - Both Mobile & Desktop */}
      <div
        className={`
          fixed lg:static inset-y-0 left-0 transform 
          ${isOpen ? 'translate-x-0' : '-translate-x-full'}
          lg:translate-x-0 transition-transform duration-200 ease-in-out
          w-64 bg-white border-r border-gray-200
          z-40 flex flex-col
        `}
      >
        {/* Desktop Logo */}
        <div className="h-16 hidden lg:flex items-center px-4 border-b border-gray-200">
          <img src="logo.png" alt="BikeCheck Logo" className="h-9 w-auto" />
          <h2 className="text-xl font-semibold text-blue-600 ml-2">
            <span className="text-gray-800">Bike</span>
            <span className="text-blue-600">Check</span>
            <span className="text-gray-800"> Pro</span>
          </h2>
        </div>

        {/* Sidebar Content */}
        <div className="p-4 lg:pt-4 pt-20 flex-1 relative">
          <div className="mb-8">
            <div className="flex items-center space-x-2 mb-4 px-4">
              <UserCircle className="h-5 w-5 text-gray-600" />
              <span className="text-sm text-gray-600">
                Moin, {username || 'Lädt...'}!
              </span>
            </div>
          </div>

          <NavigationItems />
          <FooterButtons />
        </div>
      </div>

      {/* Feedback Modal */}
      <FeedbackButton
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
      />
    </>
  );
};
