import React from 'react';
import { Check, CreditCard, Mail, Timer } from 'lucide-react';

export interface PlanFeature {
  text: string;
  included: boolean;
}

export interface SubscriptionPlan {
  name: string;
  price: string;
  period?: string;
  description: string;
  features: PlanFeature[];
  buttonText: string;
}

interface PlanCardProps {
  plan: SubscriptionPlan;
  onSelectPlan: (planName: string) => void;
  currentPlan?: string;
  loading?: boolean;
  trialEndsAt?: string;
  isTrialPeriod?: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  onSelectPlan,
  currentPlan,
  loading,
  trialEndsAt,
  isTrialPeriod,
}) => {
  const isCurrentPlan = currentPlan === plan.name.toLowerCase();
  const isPaidPlan = currentPlan !== 'hobby' && currentPlan !== undefined;

  const formatTrialEnd = (dateStr?: string) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toLocaleDateString('de-DE', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  const getButtonText = () => {
    if (isCurrentPlan && isTrialPeriod) {
      return 'Testphase aktiv';
    }
    if (isCurrentPlan) {
      return 'Aktueller Plan';
    }
    if (plan.name === 'Hobby' && isPaidPlan) {
      return 'Hobby wählen';
    }
    if (plan.name === 'Enterprise') {
      return 'Kontaktieren Sie uns';
    }
    return plan.buttonText;
  };

  return (
    <div
      className={`relative flex flex-col p-6 rounded-lg ${
        isCurrentPlan ? 'border-2 border-blue-600' : 'border border-gray-200'
      }`}
    >
      {isCurrentPlan && isTrialPeriod && (
        <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-blue-600 text-white px-4 py-1 rounded-full text-sm font-medium flex items-center gap-1">
          <Timer className="h-4 w-4" />
          Testphase bis {formatTrialEnd(trialEndsAt)}
        </div>
      )}

      <div className="mb-4">
        <h3 className="text-xl font-bold text-gray-900">{plan.name}</h3>
        <p className="mt-2 text-gray-500">{plan.description}</p>
      </div>

      <div className="mb-6">
        <span className="text-4xl font-bold text-gray-900">{plan.price}</span>
        {plan.period && (
          <span className="text-gray-500 ml-2">{plan.period}</span>
        )}
      </div>

      <ul className="mb-6 space-y-4 flex-grow">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <Check
              className={`h-5 w-5 mr-2 ${
                feature.included ? 'text-blue-600' : 'text-gray-300'
              }`}
            />
            <span
              className={`${
                feature.included ? 'text-gray-600' : 'text-gray-400'
              }`}
            >
              {feature.text}
            </span>
          </li>
        ))}
      </ul>

      <button
        onClick={() => onSelectPlan(plan.name.toLowerCase())}
        disabled={isCurrentPlan || loading}
        className={`w-full min-h-[3.5rem] py-3 px-4 flex items-center justify-center space-x-2 rounded-md ${
          isCurrentPlan
            ? 'bg-gray-100 text-gray-600 cursor-not-allowed'
            : 'bg-blue-600 text-white hover:bg-blue-700'
        } disabled:opacity-50 transition-colors duration-200 whitespace-normal text-center`}
      >
        {plan.name === 'Enterprise' ? (
          <Mail className="h-4 w-4 flex-shrink-0" />
        ) : (
          <CreditCard className="h-4 w-4 flex-shrink-0" />
        )}
        <span className="inline-block">
          {loading ? 'Laden...' : getButtonText()}
        </span>
      </button>
    </div>
  );
};

export const SubscriptionPlans: React.FC<{
  onSelectPlan: (planName: string) => void;
  currentPlan?: string;
  loading?: boolean;
  trialEndsAt?: string;
  isTrialPeriod?: boolean;
}> = ({ onSelectPlan, currentPlan, loading, trialEndsAt, isTrialPeriod }) => {
  const plans: SubscriptionPlan[] = [
    {
      name: 'Hobby',
      price: '0€',
      period: '/monat',
      description: 'Perfekt für Einzelpersonen und kleine Werkstätten',
      features: [
        { text: '1 Benutzer', included: true },
        { text: 'Unbegrenzte Gutachten', included: true },
        { text: 'Basis Support', included: true },
        { text: 'KI-Funktionen', included: false },
        { text: 'BikeCheck Pro Branding', included: true },
      ],
      buttonText: 'Kostenlos starten',
    },
    {
      name: 'Professional',
      price: '99€',
      period: '/monat',
      description: 'Ideal für wachsende Werkstätten',
      features: [
        { text: '2 Benutzer (1 Admin / 1 Mechaniker)', included: true },
        { text: 'Unbegrenzte Gutachten', included: true },
        { text: 'KI-Funktionen', included: true },
        { text: 'Priority Support', included: true },
        { text: 'Ohne Branding', included: true },
      ],
      buttonText: 'Professional wählen',
    },
    {
      name: 'Workshop',
      price: '249€',
      period: '/monat',
      description: 'Für etablierte Werkstätten',
      features: [
        { text: '7 Benutzer (2 Admin / 5 Mechaniker)', included: true },
        { text: 'Unbegrenzte Gutachten', included: true },
        { text: 'KI-Funktionen', included: true },
        { text: 'Premium Support', included: true },
        { text: 'Eigenes Branding möglich', included: true },
      ],
      buttonText: 'Workshop wählen',
    },
    {
      name: 'Enterprise',
      price: 'Individuell',
      description: 'Maßgeschneiderte Lösungen für große Werkstätten',
      features: [
        { text: 'Unbegrenzte Benutzer', included: true },
        { text: 'Unbegrenzte Gutachten', included: true },
        { text: 'Dedizierter Support', included: true },
        { text: 'Custom Entwicklung', included: true },
        { text: 'SLA Garantie', included: true },
      ],
      buttonText: 'Kontaktieren Sie uns',
    },
  ];

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center max-w-3xl mx-auto mb-12">
        <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
          Wählen Sie Ihren Plan
        </h2>
        <p className="mt-4 text-lg text-gray-500">
          Alle Pläne beinhalten unbegrenzte Gutachten und können monatlich
          gekündigt werden.
        </p>
      </div>

      <div className="grid max-w-7xl mx-auto gap-8 lg:grid-cols-4 md:grid-cols-2">
        {plans.map((plan) => (
          <PlanCard
            key={plan.name}
            plan={plan}
            onSelectPlan={onSelectPlan}
            currentPlan={currentPlan}
            loading={loading}
            trialEndsAt={trialEndsAt}
            isTrialPeriod={isTrialPeriod}
          />
        ))}
      </div>
    </div>
  );
};
