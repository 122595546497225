import React, { useEffect } from 'react';
import { useProcessStore } from '../../stores/processStore';
import { useIsMobile } from './hooks/useIsMobile';
import { DesktopView } from './views/DesktopView';
import { MobileView } from './views/MobileView';
import { useHeaderStore } from '../../stores/headerStore';

interface ProcessDetailProps {
  onBack: () => void;
}

export const ProcessDetail: React.FC<ProcessDetailProps> = ({ onBack }) => {
  const { currentProcess } = useProcessStore();
  const isMobile = useIsMobile();
  const { setTitle, setActions, setBreadcrumbs, setLoading, setShowBack } = useHeaderStore();

  if (!currentProcess) return null;

  useEffect(() => {
    setTitle(currentProcess.serialnumber);
    // Set back button handler
    setActions([
      {
        label: 'Zurück',
        icon: undefined, // The back icon is handled by the Sidebar component
        onClick: onBack,
      }
    ]);
    setShowBack(true);

    // Cleanup
    return () => {
      setTitle('');
      setActions([]);
      setShowBack(false);
    };
  }, [currentProcess, onBack]);

  if (isMobile) {
    return (
      <div className="grid grid-rows-[1fr] h-[calc(100vh-4rem)] mt-16 bg-white">
        <MobileView onBack={onBack} />
      </div>
    );
  }

  return <DesktopView onBack={onBack} />;
};

export default ProcessDetail;
