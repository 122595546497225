import React, { useEffect, useState } from 'react';
import { Teams, Models } from 'appwrite';
import { client } from '../../../utils/appwrite';
import { useAuth } from '../../../contexts/AuthContext';
import { ChevronDownIcon, CheckIcon, UserIcon } from 'lucide-react';

interface AssigneeSelectorProps {
  processId: string;
  currentAssigneeId?: string | null;
  onAssigneeChange: (userId: string | null) => Promise<void>;
}

export const AssigneeSelector: React.FC<AssigneeSelectorProps> = ({
  processId,
  currentAssigneeId,
  onAssigneeChange,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [teamMembers, setTeamMembers] = useState<Models.Membership[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { teamId } = useAuth();

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (!teamId) return;
      setIsLoading(true);
      try {
        const teams = new Teams(client);
        const response = await teams.listMemberships(teamId);
        setTeamMembers(response.memberships);
      } catch (error) {
        console.error('Error fetching team members:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamMembers();
  }, [teamId]);

  const handleAssigneeSelect = async (userId: string | null) => {
    await onAssigneeChange(userId);
    setShowDropdown(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        disabled={isLoading}
        className={`relative inline-flex items-center justify-between px-4 py-2 lg:py-1 rounded-md border
          ${showDropdown ? 'border-blue-500 ring-2 ring-blue-200' : 'border-gray-300'}
          ${isLoading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-gray-50'}`}
      >
        <UserIcon className="w-4 h-4 mr-2" />
        <span>
          {isLoading
            ? 'Laden...'
            : currentAssigneeId
              ? teamMembers.find((m) => m.userId === currentAssigneeId)
                ?.userName || 'Zugewiesen'
              : 'Nicht zugewiesen'}
        </span>
        <ChevronDownIcon className="w-4 h-4 ml-2" />
      </button>

      {showDropdown && (
        <div className="absolute z-10 mt-1 w-56 bg-white rounded-md shadow-lg border border-gray-200">
          <ul className="max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
            <li
              onClick={() => handleAssigneeSelect(null)}
              className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-50"
            >
              <span className="block truncate">Nicht zugewiesen</span>
              {!currentAssigneeId && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                  <CheckIcon className="w-4 h-4 text-blue-600" />
                </span>
              )}
            </li>
            {teamMembers.map((member) => (
              <li
                key={member.userId}
                onClick={() => handleAssigneeSelect(member.userId)}
                className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-50"
              >
                <span className="block truncate">{member.userName}</span>
                {currentAssigneeId === member.userId && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                    <CheckIcon className="w-4 h-4 text-blue-600" />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
