import { pdf } from '@react-pdf/renderer';
import { Storage } from 'appwrite';
import { PDFDocument } from 'pdf-lib';
import { client } from '../utils/appwrite';

const storage = new Storage(client);

export async function createMergedPDF(
  ProcessPDFComponent: React.ReactElement,
  quoteFileId: string | undefined
): Promise<Blob> {
  try {
    // Generate the main PDF from React components
    const mainPdfBlob = await pdf(ProcessPDFComponent).toBlob();
    const mainPdfBytes = await mainPdfBlob.arrayBuffer();

    // If there's no quote PDF, return the main PDF
    if (!quoteFileId) {
      return mainPdfBlob;
    }

    // Get the quote PDF URL from Appwrite storage and fetch it
    const quoteFileUrl = storage.getFileView('Quote', quoteFileId);
    const quoteResponse = await fetch(quoteFileUrl.toString(), {
      credentials: 'include', // This is the key change - include credentials in the request
      mode: 'cors', // Ensure CORS is handled properly
    });
    const quotePdfBytes = await quoteResponse.arrayBuffer();

    // Load both PDFs
    const mainPdfDoc = await PDFDocument.load(mainPdfBytes);
    const quotePdfDoc = await PDFDocument.load(quotePdfBytes);

    // Create a new PDF document
    const mergedPdf = await PDFDocument.create();

    // Copy pages from main PDF
    const mainPdfPages = await mergedPdf.copyPages(
      mainPdfDoc,
      mainPdfDoc.getPageIndices()
    );
    mainPdfPages.forEach((page) => mergedPdf.addPage(page));

    // Copy pages from quote PDF
    const quotePdfPages = await mergedPdf.copyPages(
      quotePdfDoc,
      quotePdfDoc.getPageIndices()
    );
    quotePdfPages.forEach((page) => mergedPdf.addPage(page));

    // Save the merged PDF
    const mergedPdfBytes = await mergedPdf.save();
    return new Blob([mergedPdfBytes], { type: 'application/pdf' });
  } catch (error) {
    console.error('Error merging PDFs:', error);
    throw error;
  }
}
