import React, { useState } from 'react';
import { useProcessStore } from '../../../stores/processStore';
import { BikeDetails } from '../components/BikeDetails';
import QuoteManagement from '../components/QuoteManagement';
import { AddDamageForm } from '../components/AddDamageForm';
import DamageList from '../components/DamageList';
import PDFGeneratorButton from '../../Export/ExportPDF';
import { getStatusStyle, getStatusText } from '../../../utils/processHelpers';
import { ArrowLeft, CheckIcon, ChevronDown, FileText, Bike, Wrench, ClipboardList } from 'lucide-react';
import { AssigneeSelector } from '../components/AssigneeSelector';

interface DesktopViewProps {
  onBack: () => void;
}

const STATUS_OPTIONS = [
  { value: 1, label: 'Neu' },
  { value: 2, label: 'Wird bearbeitet' },
  { value: 3, label: 'Wird geprüft' },
  { value: 4, label: 'Versendet' },
  { value: 5, label: 'Abgeschlossen' },
];

export const DesktopView: React.FC<DesktopViewProps> = ({ onBack }) => {
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showAddDamageForm, setShowAddDamageForm] = useState(false);

  const { currentProcess, damages, isLoading, updateProcessAssignee, updateProcessStatus } =
    useProcessStore();

  if (!currentProcess) return null;

  const handleStatusUpdate = async (newStatus: number) => {
    try {
      await updateProcessStatus(currentProcess.$id, newStatus);
      setShowStatusDropdown(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow-lg">
      {/* Enhanced Header */}
      <div className="flex items-center justify-between border-b border-gray-200 px-6 py-4 bg-white shadow-sm">
        <div className="flex items-center gap-4">
          <button
            onClick={onBack}
            className="p-2 text-gray-600 hover:text-gray-900 hover:bg-blue-50 rounded-full transition-all duration-200"
            aria-label="Zurück"
          >
            <ArrowLeft className="w-5 h-5" />
          </button>
          <div className="flex items-center gap-3">
            <div className="p-2 bg-blue-50 rounded-lg">
              <ClipboardList className="w-5 h-5 text-blue-600" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900">
              Vorgangsdetails
            </h2>
          </div>
        </div>
      </div>

      {/* Main Content - Scrollable */}
      <div className="flex-1 overflow-y-auto p-6 bg-gray-50">
        <div className="space-y-6">
          {/* Enhanced Top Section */}
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <div className="flex justify-between items-start">
              <div className="space-y-4">
                <div>
                  <h3 className="text-sm font-medium text-gray-500 flex items-center gap-2">
                    <FileText className="w-4 h-4" />
                    Vorgangsnummer
                  </h3>
                  <p className="mt-1 text-lg text-gray-900 font-medium">
                    {currentProcess.serialnumber}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Erstellungsdatum
                  </h3>
                  <p className="mt-1 text-lg text-gray-900">
                    {new Date(currentProcess.created).toLocaleDateString()}
                  </p>
                </div>
              </div>

              <div className="flex space-x-4 items-start">
                <AssigneeSelector
                  processId={currentProcess.$id}
                  currentAssigneeId={currentProcess.assignedUserId ?? null}
                  onAssigneeChange={async (userId) => {
                    await updateProcessAssignee(currentProcess.$id, userId);
                  }}
                />

                <div className="relative">
                  <button
                    onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                    className={`relative inline-flex items-center justify-between px-4 py-2 lg:py-1 rounded-lg border shadow-sm transition-all duration-200
                      ${showStatusDropdown ? 'border-blue-500 ring-2 ring-blue-100' : 'border-gray-200 hover:border-gray-300'}
                      ${getStatusStyle(currentProcess.status)}`}
                    disabled={isLoading}
                  >
                    <span>{getStatusText(currentProcess.status)}</span>
                    <ChevronDown className="w-4 h-4 ml-2 opacity-70" />
                  </button>

                  {showStatusDropdown && (
                    <div className="absolute z-10 mt-2 w-52 bg-white rounded-lg shadow-lg border border-gray-200">
                      <ul className="py-1">
                        {STATUS_OPTIONS.map((option) => (
                          <li
                            key={option.value}
                            onClick={() => handleStatusUpdate(option.value)}
                            className="cursor-pointer select-none relative py-2.5 px-4 hover:bg-gray-50 transition-colors"
                          >
                            <span className={`block truncate ${getStatusStyle(option.value)}`}>
                              {option.label}
                            </span>
                            {currentProcess.status === option.value && (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                <CheckIcon className="w-4 h-4 text-blue-600" />
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <PDFGeneratorButton process={currentProcess} damages={damages} />
              </div>
            </div>
          </div>

          {/* Enhanced Middle Section */}
          <div className="grid grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
              <div className="flex items-center gap-2 mb-4">
                <div className="p-1.5 bg-blue-50 rounded-lg">
                  <Bike className="w-5 h-5 text-blue-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900">
                  Fahrraddetails
                </h3>
              </div>
              <BikeDetails
                processId={currentProcess.$id}
                quoteFileId={currentProcess.quoteFileId}
              />
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
              <div className="flex items-center gap-2 mb-4">
                <div className="p-1.5 bg-blue-50 rounded-lg">
                  <FileText className="w-5 h-5 text-blue-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900">
                  Anhang
                </h3>
              </div>
              <QuoteManagement
                processId={currentProcess.$id}
                quoteFileId={currentProcess.quoteFileId}
                quoteName={currentProcess.quoteName}
              />
            </div>
          </div>

          {/* Enhanced Bottom Section */}
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-2">
                <div className="p-1.5 bg-blue-50 rounded-lg">
                  <Wrench className="w-5 h-5 text-blue-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900">Schäden</h3>
              </div>
              <button
                onClick={() => setShowAddDamageForm(!showAddDamageForm)}
                className="px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors shadow-sm hover:shadow-md"
              >
                {showAddDamageForm ? 'Abbrechen' : 'Schaden hinzufügen'}
              </button>
            </div>

            {showAddDamageForm && (
              <div className="w-1/2 mx-auto bg-gray-50 p-6 rounded-lg border border-gray-200">
                <AddDamageForm
                  processId={currentProcess.$id}
                  onSuccess={() => setShowAddDamageForm(false)}
                  onCancel={() => setShowAddDamageForm(false)}
                />
              </div>
            )}
            <DamageList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopView;
