import { useState } from 'react';
import { ID, Permission, Role } from 'appwrite';
import { databases } from '../utils/appwrite';
import { Process, ProcessType } from '../types/process';
import { useAuth } from '../contexts/AuthContext';
import { InspectionSmall } from './wizards/inspectionSmall/InspectionSmall';
import { useProcessStore } from '../stores/processStore';
import { Loader2 } from 'lucide-react';

interface CreateProcessFormProps {
  onCancel: () => void;
  onSuccess: (newProcess: Process) => void;
  onError: (error: string) => void;
}

export const CreateProcessForm = ({
  onCancel,
  onSuccess,
  onError,
}: CreateProcessFormProps) => {
  const [selectedType, setSelectedType] = useState<ProcessType | null>(null);
  const [serialNumber, setSerialNumber] = useState('');
  const [showWizard, setShowWizard] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const { addProcess } = useProcessStore();

  const { userId, teamId } = useAuth();
  if (!userId || !teamId) return null;

  const createProcess = async (type: ProcessType): Promise<Process> => {
    try {
      // First create an empty bike record
      const bike = await databases.createDocument(
        '67197d230036564bfc99',
        'bike',
        ID.unique(),
        {
          name: '',
          manufacturer: '',
          model: '',
          frameNumber: '',
          frameType: '',
          color: '',
          type: '',
          gears: '',
          tireSize: '',
          mudguards: '',
          rackType: '',
          transmission: '',
        },
        [
          // Document creator has full rights
          Permission.read(Role.user(userId)),
          Permission.update(Role.user(userId)),
          Permission.delete(Role.user(userId)),

          // Team members full right
          Permission.read(Role.team(teamId)),
          Permission.update(Role.team(teamId)),
          Permission.delete(Role.team(teamId)),
        ]
      );

      // Then create the process with the bikeId
      const response = await databases.createDocument(
        '67197d230036564bfc99',
        '67197d2b003aece09c07',
        ID.unique(),
        {
          serialnumber: serialNumber || `Bike-${Math.floor(10000 + Math.random() * 90000)}`,
          type: type,
          status: 1,
          bikeId: bike.$id,
        },
        [
          Permission.read(Role.user(userId)),
          Permission.update(Role.user(userId)),
          Permission.delete(Role.user(userId)),
          Permission.read(Role.team(teamId)),
          Permission.update(Role.team(teamId)),
          Permission.delete(Role.team(teamId)),
        ]
      );

      const newProcess: Process = {
        $id: response.$id,
        serialnumber: response.serialnumber,
        type: response.type,
        status: response.status,
        created: response.$createdAt,
        bikeId: bike.$id,
      };

      await addProcess(newProcess);

      if (type !== 'empty') {
        setSelectedType(type);
        setShowWizard(true);
      }

      return newProcess;
    } catch (err) {
      onError(err instanceof Error ? err.message : 'Fehler beim Erstellen des Vorgangs');
      throw err;
    }
  };

  const handleProcessTypeClick = async (type: ProcessType) => {
    setIsCreating(true);
    try {
      const process = await createProcess(type);
      onSuccess(process);
    } catch (error) {
      // Error already handled in createProcess
    } finally {
      setIsCreating(false);
    }
  };

  const handleWizardComplete = (wizardData: any) => {
    console.log('Wizard completed with data:', wizardData);
    setShowWizard(false);
  };

  const handleWizardCancel = () => {
    setShowWizard(false);
    onCancel();
  };

  if (showWizard && selectedType === 'small-inspection') {
    return (
      <InspectionSmall
        onComplete={handleWizardComplete}
        onCancel={handleWizardCancel}
      />
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-lg max-w-lg mx-auto lg:mt-12">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200">
        <h2 className="text-xl font-semibold text-gray-800">Neuen Vorgang anlegen</h2>
      </div>

      {/* Content */}
      <div className="p-6 space-y-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Vorgangsnummer
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="text"
              placeholder="Rad-123 Berta Bike"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm px-4 py-2.5"
            />
          </div>

        </div>
      </div>

      {/* Footer */}
      <div className="px-6 py-4 bg-gray-50 rounded-b-lg border-t border-gray-200 flex items-center justify-between">
        <button
          onClick={onCancel}
          disabled={isCreating}
          className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 disabled:opacity-50"
        >
          Abbrechen
        </button>

        <button
          onClick={() => handleProcessTypeClick('damage-report')}
          disabled={isCreating}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150"
        >
          {isCreating ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
              Wird erstellt...
            </>
          ) : (
            'Vorgang anlegen'
          )}
        </button>
      </div>
    </div>
  );
};
