import React, { ReactNode } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  actions?: ReactNode;
  // Optional max width class, default to max-w-sm
  maxWidth?: string;
}

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  actions,
  maxWidth = 'max-w-sm',
}: ModalProps) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <div
        className={`bg-white rounded-lg p-6 ${maxWidth} w-full`}
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-lg font-medium mb-2">{title}</h3>
        <div className="text-gray-600 mb-4">{children}</div>
        <div className="flex justify-end space-x-3">{actions}</div>
      </div>
    </div>
  );
};

export default Modal;
