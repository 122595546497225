import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Models, Functions, Account } from 'appwrite';
import { client } from '../utils/appwrite';
import { useAuth } from '../contexts/AuthContext';
import { Loader2 } from 'lucide-react';

interface SignUpFormData {
  email: string;
  password: string;
  name: string;
  organization: string;
}

interface ExecutionResponse extends Models.Execution {
  response: string;
}

interface SignUpResponse {
  success: boolean;
  user?: {
    $id: string;
    email: string;
    name: string;
  };
  team?: {
    $id: string;
    name: string;
  };
  workshop?: {
    $id: string;
    name: string;
  };
  message?: string;
}

interface SignUpFormProps {
  onCancel: () => void;
  onError: (error: string) => void;
  isLoading?: boolean;
}

export const SignUpForm = ({ onCancel, onError }: SignUpFormProps) => {
  const [formData, setFormData] = useState<SignUpFormData>({
    email: '',
    password: '',
    name: '',
    organization: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { login, sendVerificationEmail } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      const functions = new Functions(client);
      const response = (await functions.createExecution(
        '6723f9e000315399705a',
        JSON.stringify({
          action: 'signup',
          email: formData.email,
          password: formData.password,
          name: formData.name,
          organization: formData.organization,
        })
      )) as ExecutionResponse;

      const data = JSON.parse(response.responseBody) as SignUpResponse;

      if (!data.success) {
        const errorMsg = data.message?.includes('ID already exists')
          ? 'Diese E-Mail-Adresse wird bereits verwendet. Bitte verwenden Sie eine andere E-Mail-Adresse oder melden Sie sich an.'
          : data.message ||
            'Bei der Registrierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.';
        setErrorMessage(errorMsg);
        throw new Error(errorMsg);
      }

      try {
        await login(formData.email, formData.password);
        // Send verification email using the context function

        console.log('Sending verification email...');
        await sendVerificationEmail();
        console.log('Verification email sent!');
        // Redirect to verification page instead of dashboard
        navigate('/verify-email', { replace: true });
      } catch (loginError) {
        setErrorMessage(
          'Konto wurde erstellt, aber die Anmeldung ist fehlgeschlagen. Bitte melden Sie sich manuell an.'
        );
        navigate('/', { replace: true });
      }
    } catch (error) {
      const errorMsg =
        error instanceof Error
          ? error.message
          : 'Bei der Registrierung ist ein Fehler aufgetreten';
      setErrorMessage(errorMsg);
      onError(errorMsg);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrorMessage(null);
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold text-center mb-8">
          Werkstatt registrieren
        </h2>
      </div>

      {errorMessage && (
        <div className="rounded-md bg-red-50 p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{errorMessage}</p>
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            E-Mail
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={formData.email}
              onChange={handleInputChange}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-3"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Passwort
          </label>
          <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              required
              minLength={8}
              value={formData.password}
              onChange={handleInputChange}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-3"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Name
          </label>
          <div className="mt-2">
            <input
              id="name"
              name="name"
              type="text"
              required
              value={formData.name}
              onChange={handleInputChange}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-3"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="organization"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Werkstattname
          </label>
          <div className="mt-2">
            <input
              id="organization"
              name="organization"
              type="text"
              required
              value={formData.organization}
              onChange={handleInputChange}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-3"
            />
          </div>
        </div>

        <div className="flex gap-3">
          <button
            type="button"
            onClick={onCancel}
            disabled={isSubmitting}
            className="flex-1 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Zurück zum Login
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="flex-1 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center justify-center"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="animate-spin mr-2 h-4 w-4" />
                Erstelle Konto...
              </>
            ) : (
              'Konto erstellen'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
