import { create } from 'zustand'
import { databases } from '../utils/appwrite'
import { Bike } from '../types/bike'
import { Functions } from 'appwrite'
import { client } from '../utils/appwrite'

const functions = new Functions(client)

interface BikeStore {
  bike: Bike | null
  isLoading: boolean
  error: string | null
  
  // Actions
  setBike: (bike: Bike | null) => void
  updateBike: (updates: Partial<Bike>) => void
  fetchBike: (processId: string) => Promise<void>
  saveBike: (processId: string) => Promise<void>
  extractFromQuote: (fileId: string) => Promise<void>
  resetStore: () => void
  
  // Form field updates
  setFormField: (field: keyof Bike, value: string) => void
}

const initialState = {
  bike: null,
  isLoading: false,
  error: null,
}

export const useBikeStore = create<BikeStore>((set, get) => {
  const normalize = (str: string): string => {
    if (!str) return ''
    if (typeof str !== 'string') return str
    return str
      .toLowerCase()
      .replace(/[äáàâ]/g, 'a')
      .replace(/[ëéèê]/g, 'e')
      .replace(/[ïíìî]/g, 'i')
      .replace(/[öóòô]/g, 'o')
      .replace(/[üúùû]/g, 'u')
      .replace(/[ß]/g, 'ss')
      .replace(/[^a-z0-9]/g, '')
  }

  const mapExtractedFields = (extractedData: Record<string, string>) => {
    const fieldMappings = {
      name: {
        matches: ['name', 'bezeichnung', 'fahrradbezeichnung', 'modellname'],
        field: 'name' as keyof Bike,
      },
      manufacturer: {
        matches: ['hersteller', 'herstellertyp', 'marke', 'fabrikant', 'produzent'],
        field: 'manufacturer' as keyof Bike,
      },
      model: {
        matches: ['modell', 'typ', 'fabrikat', 'ausführung', 'version', 'serie'],
        field: 'model' as keyof Bike,
      },
      frameNumber: {
        matches: ['rahmennummer', 'rahmenseriennummer', 'seriennummer', 'framenumber', 'rahmennr'],
        field: 'frameNumber' as keyof Bike,
      },
      frameType: {
        matches: ['rahmenmaterial', 'rahmenart', 'material', 'rahmentyp'],
        field: 'frameType' as keyof Bike,
      },
      color: {
        matches: ['farbe', 'rahmenfarbe', 'farbgebung', 'lackierung', 'color'],
        field: 'color' as keyof Bike,
      },
      type: {
        matches: ['fahrradart', 'fahrradtyp', 'art', 'kategorie', 'biketype'],
        field: 'type' as keyof Bike,
      },
      gears: {
        matches: ['gänge', 'gangzahl', 'schaltung', 'gangschaltung', 'übersetzung', 'gange'],
        field: 'gears' as keyof Bike,
      },
      tireSize: {
        matches: ['reifengröße', 'reifenmaß', 'laufradgröße', 'radgröße', 'reifendimension', 'zoll'],
        field: 'tireSize' as keyof Bike,
      },
      mudguards: {
        matches: ['schutzbleche', 'schutzblech', 'kotflügel', 'spritzschutz'],
        field: 'mudguards' as keyof Bike,
      },
      rackType: {
        matches: ['gepäckträger', 'träger', 'rack', 'carrier', 'gepacktrager'],
        field: 'rackType' as keyof Bike,
      },
      transmission: {
        matches: ['schaltung', 'schaltwerk', 'gangschaltung', 'antrieb', 'transmission'],
        field: 'transmission' as keyof Bike,
      },
    }

    const mappedData: Partial<Bike> = {}

    Object.entries(extractedData).forEach(([key, value]) => {
      if (!value) return

      const normalizedKey = normalize(key)
      const normalizedValue = normalize(value)

      Object.values(fieldMappings).forEach((mapping) => {
        const keyMatch = mapping.matches.some(
          (pattern) =>
            normalizedKey.includes(normalize(pattern)) ||
            normalize(pattern).includes(normalizedKey)
        )

        const valueMatch = mapping.matches.some((pattern) =>
          normalizedValue.includes(normalize(pattern))
        )

        if ((keyMatch || valueMatch) && !mappedData[mapping.field]) {
          mappedData[mapping.field] = value.trim()
        }
      })
    })

    return mappedData
  }

  return {
    ...initialState,
    
    resetStore: () => set(initialState),
    
    setBike: (bike) => set({ bike }),
    
    updateBike: (updates) => set((state) => ({
      bike: state.bike ? { ...state.bike, ...updates } : null
    })),
    
    setFormField: (field, value) => set((state) => ({
      bike: state.bike ? { ...state.bike, [field]: value } : null
    })),
    
    fetchBike: async (processId: string) => {
      set({ isLoading: true, error: null })
      try {
        const processDoc = await databases.getDocument(
          '67197d230036564bfc99',
          '67197d2b003aece09c07',
          processId
        )
        if (processDoc.bikeId) {
          const bikeDoc = await databases.getDocument(
            '67197d230036564bfc99',
            'bike',
            processDoc.bikeId
          )
          
          const formattedBike: Bike = {
            $id: bikeDoc.$id,
            name: bikeDoc.name,
            manufacturer: bikeDoc.manufacturer,
            model: bikeDoc.model,
            frameNumber: bikeDoc.frameNumber,
            frameType: bikeDoc.frameType,
            color: bikeDoc.color,
            type: bikeDoc.type,
            gears: bikeDoc.gears,
            tireSize: bikeDoc.tireSize,
            mudguards: bikeDoc.mudguards,
            rackType: bikeDoc.rackType,
            transmission: bikeDoc.transmission,
          }
          set({ bike: formattedBike, isLoading: false })
        } else {
          set({ bike: null, isLoading: false })
        }
      } catch (error) {
        console.error('Error loading bike data:', error)
        set({ error: 'Fehler beim Laden der Fahrraddetails', isLoading: false })
      }
    },
    
    saveBike: async (processId: string) => {
      const { bike } = get()
      set({ isLoading: true, error: null })
      
      try {
        const data = {
          ...bike,
          process: processId
        }
        let savedBikeId: string
        
        if (bike?.$id) {
          await databases.updateDocument(
            '67197d230036564bfc99',
            'bike',
            bike.$id,
            data
          )
          savedBikeId = bike.$id
        } else {
          const response = await databases.createDocument(
            '67197d230036564bfc99',
            'bike',
            'unique()',
            data
          )
          savedBikeId = response.$id
          set((state) => ({
            bike: state.bike ? { ...state.bike, $id: savedBikeId } : null
          }))
        }
        
        await databases.updateDocument(
          '67197d230036564bfc99',
          '67197d2b003aece09c07',
          processId,
          { bikeId: savedBikeId }
        )
        set({ isLoading: false })
      } catch (error) {
        console.error('Error saving bike:', error)
        set({ error: 'Fehler beim Speichern der Fahrraddetails', isLoading: false })
      }
    },
    
    extractFromQuote: async (quoteFileId: string) => {
      if (!quoteFileId) {
        set({ error: 'Keine Datei zum Verarbeiten gefunden' })
        return
      }

      set({ isLoading: true, error: null })

      try {
        const execution = await functions.createExecution(
          '671ea1220006c454d6bf',
          JSON.stringify({ fileId: quoteFileId })
        )

        const responseData = JSON.parse(execution.responseBody)

        if (responseData.success && responseData.formFields) {
          const mappedData = mapExtractedFields(responseData.formFields)
          set((state) => ({
            bike: state.bike ? { ...state.bike, ...mappedData } : mappedData
          }))
        } else {
          throw new Error(responseData.message || 'Keine Daten extrahiert')
        }
      } catch (error) {
        console.error('Error during processing:', error)
        set({ 
          error: error instanceof Error 
            ? error.message 
            : 'Fehler bei der Dokumentenverarbeitung' 
        })
      } finally {
        set({ isLoading: false })
      }
    }
  }
})
