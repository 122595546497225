import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { databases, storage } from '../utils/appwrite';
import { ID } from 'appwrite';

type FeedbackType = 'bug' | 'feature' | 'other';

interface Feedback {
  userId: string;
  type: FeedbackType;
  message: string;
  path: string;
  metadata: string;
  status: string;
  images?: string[];
}

interface FeedbackButtonProps {
  isOpen: boolean;
  onClose: () => void;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({ isOpen, onClose }) => {
  const [type, setType] = useState<FeedbackType>('bug');
  const [message, setMessage] = useState('');
  const [screenshots, setScreenshots] = useState<File[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { userId } = useAuth();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setScreenshots((prev) => [...prev, ...files]);
  };

  const removeScreenshot = (index: number) => {
    setScreenshots((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userId) return;
    setIsSending(true);

    try {
      // Upload screenshots first
      const uploadedImageIds: string[] = [];

      for (const file of screenshots) {
        const fileId = ID.unique();
        await storage.createFile(
          '674b3136001d0f3ff549',
          fileId,
          file
        );
        uploadedImageIds.push(fileId);
      }

      const metadata = JSON.stringify({
        browser: navigator.userAgent,
        screenSize: `${window.innerWidth}x${window.innerHeight}`,
        timestamp: new Date().toISOString(),
      });

      const feedback: Feedback = {
        userId,
        type,
        message,
        path: window.location.pathname,
        metadata,
        status: 'new',
        images: uploadedImageIds,
      };

      await databases.createDocument(
        '67197d230036564bfc99',
        'feedback',
        ID.unique(),
        feedback
      );

      setShowSuccess(true);
      setTimeout(() => {
        onClose();
        setShowSuccess(false);
        setMessage('');
        setType('bug');
        setScreenshots([]);
      }, 2000);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setIsSending(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Feedback geben</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {showSuccess ? (
          <div className="text-center py-4">
            <svg
              className="w-16 h-16 text-green-500 mx-auto mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <p className="text-lg font-medium">Vielen Dank für dein Feedback!</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Art des Feedbacks
              </label>
              <select
                value={type}
                onChange={(e) => setType(e.target.value as FeedbackType)}
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="bug">Problem melden</option>
                <option value="feature">Feature vorschlagen</option>
                <option value="other">Sonstiges</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Deine Nachricht
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[100px]"
                placeholder="Beschreibung..."
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Screenshots hinzufügen (optional)
              </label>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
                className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
              {screenshots.length > 0 && (
                <div className="mt-2 space-y-2">
                  {screenshots.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between bg-gray-50 p-2 rounded"
                    >
                      <span className="text-sm text-gray-600 truncate">
                        {file.name}
                      </span>
                      <button
                        type="button"
                        onClick={() => removeScreenshot(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <button
              type="submit"
              disabled={isSending}
              className="w-full bg-blue-600 text-white rounded-md py-2 px-4 hover:bg-blue-700 transition-colors duration-200 disabled:opacity-50"
            >
              {isSending ? 'Wird gesendet...' : 'Feedback senden'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default FeedbackButton;
