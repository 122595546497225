export const getStatusStyle = (status: number) => {
  switch (status) {
    case 1: // 'Neu' (New)
      return 'bg-purple-100 text-purple-800'; // Purple for new items
    case 2: // 'Wird bearbeitet' (Being processed)
      return 'bg-blue-100 text-blue-800'; // Blue for in-progress
    case 3: // 'Wird geprüft' (Being checked)
      return 'bg-yellow-100 text-yellow-800'; // Yellow for review/checking
    case 4: // 'Versendet' (Sent)
      return 'bg-green-100 text-green-800'; // Green for sent/completed
    case 5: // 'Abgeschlossen' (Completed/Closed)
      return 'bg-gray-100 text-gray-800'; // Gray for closed/archived
    default: // 'Unbekannt' (Unknown)
      return 'bg-red-100 text-red-800'; // Red for unknown/error
  }
};

export const getStatusText = (status: number) => {
  switch (status) {
    case 1:
      return 'Neu';
    case 2:
      return 'Wird bearbeitet';
    case 3:
      return 'Wird geprüft';
    case 4:
      return 'Versendet';
    case 5:
      return 'Abgeschlossen';
    default:
      return 'Unbekannt';
  }
};
