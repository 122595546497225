import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { Logo } from './Logo';
import { Workshop } from '../../types/workshop';

interface HeaderProps {
  processNumber: string;
  username: string;
  workshop: Workshop;
  bikeNumber: string;
}

export const Header: React.FC<HeaderProps> = ({ processNumber, username, workshop, bikeNumber }) => (
  <View style={styles.header}>
    <View style={styles.headerLeft}>
      <Text style={styles.headerTitle}>{bikeNumber}</Text>
      <Text style={styles.headerSubtitle}>
        Vorgangs-Nr.: {processNumber}
      </Text>
      <Text style={styles.headerSubtitle}>
        Datum: {' '}
        {new Date().toLocaleDateString('de-DE')}
        {/*
        Gutachter: {username}
        */}
      </Text>
    </View>
    <Logo workshop={workshop} />
  </View>
);
