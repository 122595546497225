import { create } from 'zustand';

interface HeaderAction {
  label: string;
  icon?: React.ReactNode;  // Changed from string to ReactNode for Lucide icons
  onClick: () => void;
}

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface HeaderState {
  title: string;
  subtitle?: string;
  actions: HeaderAction[];
  showBack: boolean;
  breadcrumbs: BreadcrumbItem[];
  loading: boolean;

  // Actions
  setTitle: (title: string) => void;
  setSubtitle: (subtitle?: string) => void;
  setActions: (actions: HeaderAction[]) => void;
  setShowBack: (show: boolean) => void;
  setBreadcrumbs: (breadcrumbs: BreadcrumbItem[]) => void;
  setLoading: (loading: boolean) => void;
  reset: () => void;
}

const initialState = {
  title: '',
  subtitle: undefined,
  actions: [],
  showBack: false,
  breadcrumbs: [],
  loading: false,
};

export const useHeaderStore = create<HeaderState>((set) => ({
  ...initialState,
  setTitle: (title) => set({ title }),
  setSubtitle: (subtitle) => set({ subtitle }),
  setActions: (actions) => set({ actions }),
  setShowBack: (show) => set({ showBack: show }), // Added setter
  setBreadcrumbs: (breadcrumbs) => set({ breadcrumbs }),
  setLoading: (loading) => set({ loading }),
  reset: () => set(initialState),
}));
