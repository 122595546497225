import { Loader2, Save, Upload, CheckCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useBikeStore } from '../../../stores/useBikeStore';

interface BikeDetailsProps {
  processId: string;
  quoteFileId?: string;
  bike?: any;
}

interface BikeDetails {
  name?: string;
  manufacturer?: string;
  model?: string;
  frameNumber?: string;
  frameType?: string;
  color?: string;
  type?: string;
  gears?: string;
  tireSize?: string;
  mudguards?: string;
  rackType?: string;
  transmission?: string;
}

const FORM_FIELDS = [
  { id: 'name', label: 'Name/Bezeichnung', name: 'name' },
  { id: 'manufacturer', label: 'Hersteller', name: 'manufacturer' },
  { id: 'model', label: 'Modell', name: 'model' },
  { id: 'frameNumber', label: 'Rahmennummer', name: 'frameNumber' },
  { id: 'color', label: 'Farbe', name: 'color' },
  { id: 'type', label: 'Fahrradart', name: 'type' },
] as const;

export const BikeDetails: React.FC<BikeDetailsProps> = ({
  processId,
  quoteFileId,
}) => {
  const {
    bike,
    isLoading,
    error,
    fetchBike,
    saveBike,
    setFormField,
    extractFromQuote,
    resetStore,
  } = useBikeStore();

  const [initialBikeState, setInitialBikeState] = useState<BikeDetails | null>(null);
  const [isProcessingQuote, setIsProcessingQuote] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);

  useEffect(() => {
    fetchBike(processId);
    return () => resetStore();
  }, [processId, resetStore]);

  useEffect(() => {
    if (bike && !initialBikeState) {
      setInitialBikeState(bike);
    }
  }, [bike, initialBikeState]);

  useEffect(() => {
    if (showSaveSuccess) {
      const timer = setTimeout(() => {
        setShowSaveSuccess(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showSaveSuccess]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormField(name as keyof BikeDetails, value);
  };

  const handleExtractFromQuote = async () => {
    if (!quoteFileId) return;
    setIsProcessingQuote(true);
    try {
      await extractFromQuote(quoteFileId);
    } finally {
      setIsProcessingQuote(false);
    }
  };

  const hasChanges = (): boolean => {
    if (!initialBikeState || !bike) return false;
    return Object.keys(bike).some((key) => {
      const k = key as keyof BikeDetails;
      return bike[k] !== initialBikeState[k];
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await saveBike(processId);
      setInitialBikeState(bike);
      setShowSaveSuccess(true);
    } catch (error) {
      console.error('Save failed:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    // Remove the h-full to prevent stretching
    <div className="flex flex-col min-h-0">
      {error && (
        <div className="mb-2 px-2 py-1.5 text-sm text-red-600 bg-red-50 rounded-md">
          {error}
        </div>
      )}

      {/* Form area */}
      <div className="min-h-0">
        <div className="grid grid-cols-2 gap-x-3 gap-y-2">
          {FORM_FIELDS.map((field) => (
            <div key={field.id}>
              <label
                htmlFor={field.id}
                className="block text-sm font-medium text-gray-600 mb-1"
              >
                {field.label}
              </label>
              <input
                id={field.id}
                type="text"
                name={field.name}
                value={bike?.[field.name as keyof BikeDetails] || ''}
                onChange={handleInputChange}
                className="block w-full h-8 px-2.5 text-sm text-gray-900 bg-white rounded-md border border-gray-200 
                focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-transparent
                placeholder:text-gray-400 disabled:bg-gray-50 disabled:text-gray-500"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Button area with reduced spacing */}
      <div className="flex justify-end gap-2 mt-3 pt-2 border-t border-gray-200">
        <button
          onClick={handleExtractFromQuote}
          disabled={isLoading || !quoteFileId || isProcessingQuote}
          className={`inline-flex items-center px-2.5 h-7 text-xs font-medium rounded
          ${isProcessingQuote || !quoteFileId || isLoading
              ? 'bg-gray-100 text-gray-500'
              : 'bg-white text-blue-600 border border-blue-200 hover:bg-blue-50'
            }`}
        >
          {isProcessingQuote ? (
            <>
              <Loader2 className="w-3 h-3 mr-1 animate-spin" />
              <span className="whitespace-nowrap">Wird verarbeitet...</span>
            </>
          ) : (
            <>
              <Upload className="w-3 h-3 mr-1" />
              <span className="whitespace-nowrap">Aus Anhang übernehmen</span>
            </>
          )}
        </button>

        <button
          onClick={handleSave}
          disabled={isLoading || isSaving || !hasChanges()}
          className={`inline-flex items-center px-2.5 h-7 text-xs font-medium rounded min-w-[80px]
          ${isLoading || isSaving || !hasChanges()
              ? 'bg-gray-100 text-gray-500'
              : showSaveSuccess
                ? 'bg-green-600 text-white'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
        >
          {isSaving ? (
            <>
              <Loader2 className="w-3 h-3 mr-1 animate-spin" />
              <span className="whitespace-nowrap">Speichern...</span>
            </>
          ) : showSaveSuccess ? (
            <>
              <CheckCircle className="w-3 h-3 mr-1" />
              <span className="whitespace-nowrap">Gespeichert</span>
            </>
          ) : (
            <>
              <Save className="w-3 h-3 mr-1" />
              <span className="whitespace-nowrap">Speichern</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default BikeDetails;
