import React, { useState } from 'react';
import {
  MessageSquarePlus,
  FileText,
  Settings,
  Users,
  Smartphone,
  Monitor,
  FileOutput,
  Wrench,
  Camera,
  PenTool,
  FileCheck,
  AlertCircle,
  ChevronRight,
  Building2,
  Video
} from 'lucide-react';
import FeedbackButton from '../components/FeedbackButton';
import { useHeaderStore } from '../stores/headerStore';

const HelpPage: React.FC = () => {
  const { setTitle } = useHeaderStore();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  React.useEffect(() => {
    setTitle('Hilfe & Support');
    return () => setTitle('');
  }, [setTitle]);

  return (
    <div className="max-w-4xl mx-auto p-8 lg:pt-8 pt-20">
      <div className="flex items-center gap-3 mb-12">
        <FileText className="h-8 w-8 text-blue-600 flex-shrink-0" />
        <div>
          <h1 className="text-2xl font-bold mb-2">
            Willkommen bei{' '}
            <span className="whitespace-nowrap">
              <span className="text-gray-800">Bike</span>
              <span className="text-blue-600">Check</span>
              <span className="text-gray-800"> Pro</span>
            </span>
          </h1>
          <div className="h-1 w-20 bg-blue-600 rounded"></div>
        </div>
      </div>


      <h2 className="text-2xl font-semibold mb-6 flex items-center gap-2">
        <AlertCircle className="h-6 w-6 text-blue-600" />
        So einfach funktioniert es
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        <div className="bg-gradient-to-br from-blue-50 to-white p-6 rounded-xl shadow-sm border border-blue-100 hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-blue-100 rounded-lg">
              <FileText className="h-6 w-6 text-blue-600" />
            </div>
            <h3 className="text-lg font-semibold">1. Schäden erfassen</h3>
          </div>
          <p className="text-gray-600">Direkt an der Werkbank erstellst du mit deinem Smartphone einen neuen Vorgang und dokumentierst die Schäden mit Fotos oder Videos.</p>
        </div>

        <div className="bg-gradient-to-br from-blue-50 to-white p-6 rounded-xl shadow-sm border border-blue-100 hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-blue-100 rounded-lg">
              <PenTool className="h-6 w-6 text-blue-600" />
            </div>
            <h3 className="text-lg font-semibold">2. Details ausarbeiten</h3>
          </div>
          <p className="text-gray-600">Am Computer im Büro ergänzt du oder dein Team die Beschreibungen und den Kostenvoranschlag. Die KI hilft dabei mit Vorschlägen.</p>
        </div>

        <div className="bg-gradient-to-br from-blue-50 to-white p-6 rounded-xl shadow-sm border border-blue-100 hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-blue-100 rounded-lg">
              <FileCheck className="h-6 w-6 text-blue-600" />
            </div>
            <h3 className="text-lg font-semibold">3. PDF erstellen</h3>
          </div>
          <p className="text-gray-600">Mit einem Klick erstellst du ein professionelles Gutachten inklusive aller Bilder und dem Kostenvoranschlag.</p>
        </div>
      </div>

      <section className="mb-12 space-y-6">
        <div className="flex items-center gap-2 mb-6">
          <Building2 className="h-6 w-6 text-blue-600" />
          <h2 className="text-2xl font-semibold">Richte die App für deine Werkstatt ein</h2>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3 mb-4">
            <Settings className="h-6 w-6 text-blue-600" />
            <h3 className="text-xl font-medium">Werkstatt-Einrichtung</h3>
          </div>
          <p className="mb-4 text-gray-600">Hinterlege unter "Einstellungen" du deine vollständigen Werkstattdaten. Diese erscheinen später auf deinen Gutachten:</p>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-3 pl-5">
            {[
              'Werkstattname und Logo',
              'Vollständige Adresse',
              'Telefon und E-Mail',
              'Bankverbindung',
              'Steuernummer und USt-ID',
              'Handelsregister und Registergericht',
            ].map((item) => (
              <li key={item} className="flex items-center gap-2 text-gray-600">
                <ChevronRight className="h-4 w-4 text-blue-600 flex-shrink-0" />
                {item}
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3 mb-4">
            <Users className="h-6 w-6 text-blue-600" />
            <h3 className="text-xl font-medium">Team organisieren</h3>
          </div>
          <p className="text-gray-600">
            BikeCheck Pro optimiert die Zusammenarbeit zwischen Werkstatt und Büro: Die Mechaniker dokumentieren die Schäden direkt an der Werkbank, während das Büro-Team die Gutachten finalisiert und versendet. So behält jeder den Überblick und kann sich auf seine Kernaufgaben konzentrieren. Die Team-Verwaltung findest du im Hauptmenü.
          </p>
        </div>
      </section>

      <section className="mb-12">
        <div className="flex items-center gap-2 mb-6">
          <FileOutput className="h-6 w-6 text-blue-600" />
          <h2 className="text-2xl font-semibold">Der Weg zum fertigen Gutachten</h2>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow mb-6">
          <div className="flex items-center gap-3 mb-4">
            <Smartphone className="h-6 w-6 text-blue-600" />
            <h3 className="text-xl font-medium">Mobile Schadensdokumentation</h3>
          </div>
          <p className="mb-4 text-gray-600">Die Schadensdokumentation erledigst du direkt am Fahrrad mit deinem Smartphone:</p>
          <ul className="space-y-2 pl-5">
            {[
              'Öffne die App auf deinem Smartphone und erstelle einen neuen Vorgang',
              'Dokumentiere jeden Schaden entweder mit mehreren Fotos oder einem kurzen Video (max. 30 Sekunden)',
              'Mit KI kannst du Vorschläge zur Schadensbeschreibung bekommen',
            ].map((item) => (
              <li key={item} className="flex items-center gap-2 text-gray-600">
                <ChevronRight className="h-4 w-4 text-blue-600 flex-shrink-0" />
                {item}
              </li>
            ))}
          </ul>
          <p className="mt-4 text-gray-600">Die Aufnahmen sollten bei guter Beleuchtung erfolgen. Achte besonders bei Videos darauf, den Schaden aus verschiedenen Perspektiven zu zeigen.</p>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow mb-6">
          <div className="flex items-center gap-3 mb-4">
            <Monitor className="h-6 w-6 text-blue-600" />
            <h3 className="text-xl font-medium">Weiterbearbeitung am Computer</h3>
          </div>
          <p className="mb-4 text-gray-600">Die detaillierte Ausarbeitung des Gutachtens erfolgt anschließend im Büro am Computer:</p>
          <ul className="space-y-2 pl-5">
            {[
              'Prüfe und verfeinere die KI-generierten Schadensbeschreibungen',
              'Lade Anhänge wie einen Kostenvoranschlag als PDF hoch - dieser wird automatisch dem Gutachten beigefügt',
              'Die KI unterstützt dich dabei, indem sie relevante Fahrraddaten aus dem Dokument extrahiert'
            ].map((item) => (
              <li key={item} className="flex items-center gap-2 text-gray-600">
                <ChevronRight className="h-4 w-4 text-blue-600 flex-shrink-0" />
                {item}
              </li>
            ))}
          </ul>
          <p className="mt-4 text-gray-600">Die KI-Funktionen sind kontinuierlich in Entwicklung. Prüfe die Vorschläge immer auf ihre Richtigkeit und passe sie bei Bedarf an.</p>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3 mb-4">
            <FileCheck className="h-6 w-6 text-blue-600" />
            <h3 className="text-xl font-medium">PDF-Export: Das fertige Gutachten</h3>
          </div>
          <p className="mb-4 text-gray-600">Mit einem Klick erstellt BikeCheck Pro ein professionelles PDF-Dokument. Dieses enthält:</p>
          <ul className="space-y-2 pl-5">
            {[
              'Dein Werkstatt-Branding und alle relevanten Kontaktdaten',
              'Eine übersichtliche Dokumentation aller Schäden mit Bildern oder Videos',
              'Detaillierte, technische Beschreibungen der Schäden',
              'Den angehängten Kostenvoranschlag'
            ].map((item) => (
              <li key={item} className="flex items-center gap-2 text-gray-600">
                <ChevronRight className="h-4 w-4 text-blue-600 flex-shrink-0" />
                {item}
              </li>
            ))}
          </ul>
          <p className="mt-4 text-gray-600">Das fertige Gutachten eignet sich perfekt für die Kommunikation mit Versicherungen und Kunden.</p>
        </div>
      </section>

      <section className="mb-12">
        <div className="flex items-center gap-2 mb-6">
          <Camera className="h-6 w-6 text-blue-600" />
          <h2 className="text-2xl font-semibold">Video-Dokumentation</h2>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-blue-100 rounded-lg">
              <Video className="h-6 w-6 text-blue-600" />
            </div>
            <h3 className="text-xl font-medium">Sicherer Video-Service</h3>
          </div>

          <p className="mb-4 text-gray-600">
            Neben Fotos kannst du Schäden auch per Video dokumentieren. Mit unserem sicheren Video-Service teilst du diese professionell und datenschutzkonform mit Versicherungen und Kunden:
          </p>

          <ul className="space-y-2 pl-5">
            {[
              'Videos sind nur über verschlüsselte, temporäre Links zugänglich',
              'Automatische Einbindung in dein Gutachten',
              'Sichere Speicherung in deutschen Rechenzentren',
              'Optimiert für die professionelle Kommunikation mit Versicherungen',
              'Direkte Wiedergabe im Browser - kein Download oder Login erforderlich'
            ].map((item) => (
              <li key={item} className="flex items-center gap-2 text-gray-600">
                <ChevronRight className="h-4 w-4 text-blue-600 flex-shrink-0" />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section>
        <div className="flex items-center gap-2 mb-4">
          <Wrench className="h-6 w-6 text-blue-600" />
          <h2 className="text-2xl font-semibold">Tipp für effizientes Arbeiten</h2>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
          <p className="text-gray-600">Die Trennung zwischen mobiler Dokumentation und Büroarbeit macht dich besonders effizient: Erfasse alle Schäden direkt am Fahrrad mit dem Smartphone. Die zeitaufwändige Ausformulierung und Finalisierung kann später bequem im Büro erledigt werden. So sparst du wertvolle Zeit in der Werkstatt und behältst trotzdem alle Details im Blick.</p>
        </div>
      </section>

      <section className="mt-12 mb-8">
        <div className="bg-gradient-to-br from-blue-50 to-white p-8 rounded-xl shadow-sm border border-blue-100">
          <div className="text-center max-w-2xl mx-auto">
            <h2 className="text-2xl font-semibold mb-4 flex items-center justify-center gap-2">
              <AlertCircle className="h-6 w-6 text-blue-600" />
              Noch Fragen?
            </h2>
            <p className="mb-6 text-gray-600">
              Wir verbessern BikeCheck Pro ständig. Wenn du Fragen oder Anregungen hast,
              freuen wir uns über dein Feedback.
            </p>
            <button
              onClick={() => setIsFeedbackOpen(true)}
              className="inline-flex items-center px-6 py-3 bg-blue-600 hover:bg-blue-700 
                       text-white rounded-lg transition-all duration-200 shadow-sm hover:shadow-md"
            >
              <MessageSquarePlus className="h-5 w-5 mr-2" />
              Feedback senden
            </button>
          </div>
        </div>
      </section>

      <FeedbackButton
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
      />
    </div>
  );
};

export default HelpPage;
