import React, { useState, useCallback } from 'react';
import { Permission, Role } from 'appwrite';
import { useAuth } from '../../../contexts/AuthContext';
import { Loader2, Camera, Video } from 'lucide-react';
import CameraCapture from './CameraCapture';
import VideoCapture from './VideoCapture';
import { useIsMobile } from '../hooks/useIsMobile';
import { useProcessStore } from '../../../stores/processStore';
import imageCompression from 'browser-image-compression';

interface AddDamageFormProps {
  processId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

type MediaType = 'photo' | 'video' | null;

interface FormData {
  title: string;
  description: string;
  mediaFiles: File[];
  mediaType: MediaType;
}

const compressImage = async (file: File): Promise<File> => {
  if (!file?.size) {
    throw new Error(`Invalid file: ${file?.name || 'Unknown file'}`);
  }

  const options = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 1200,
    useWebWorker: true,
    initialQuality: 0.7,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    const timestamp = new Date().getTime();
    const newFileName = `${timestamp}-${file.name.replace(/\.[^/.]+$/, '')}.jpg`;

    return new File([compressedFile], newFileName, {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });
  } catch (error) {
    console.error('Compression error:', error);
    throw error;
  }
};

const DesktopDropzone: React.FC<{ onDrop: (files: File[]) => void }> = ({ onDrop }) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const processFiles = async (files: File[]) => {
    setIsProcessing(true);
    try {
      const compressedFiles = await Promise.all(
        files.map(file => compressImage(file))
      );
      onDrop(compressedFiles);
    } catch (error) {
      console.error('Error processing files:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true);
    } else if (e.type === 'dragleave') {
      setIsDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);

    const files = Array.from(e.dataTransfer.files).filter(file =>
      file.type.startsWith('image/')
    );

    if (files.length > 0) {
      processFiles(files);
    }
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files).filter(file =>
        file.type.startsWith('image/')
      );
      if (files.length > 0) {
        processFiles(files);
      }
    }
  }, []);

  return (
    <div
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className={`relative border-2 border-dashed rounded-lg p-8 text-center transition-colors duration-200 ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'
        }`}
    >
      {isProcessing && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80">
          <div className="flex items-center space-x-2 text-blue-500">
            <Loader2 className="animate-spin h-5 w-5" />
            <span>Bilder werden verarbeitet...</span>
          </div>
        </div>
      )}

      <label className="cursor-pointer">
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleChange}
          disabled={isProcessing}
          className="hidden"
        />
        <div className="space-y-2">
          <div className="flex justify-center">
            <Camera className="w-8 h-8 text-gray-400" />
          </div>
          <p className="text-sm text-gray-600">
            {isDragActive
              ? 'Bilder hier ablegen ...'
              : 'Bilder hierher ziehen oder klicken zum Auswählen'}
          </p>
        </div>
      </label>
    </div>
  );
};

export const AddDamageForm: React.FC<AddDamageFormProps> = ({
  processId,
  onSuccess,
  onCancel,
}) => {
  const { userId, teamId } = useAuth();
  const { addDamage, isLoading, error, damages } = useProcessStore();
  const isMobile = useIsMobile();

  const [formData, setFormData] = useState<FormData>({
    title: '',
    description: '',
    mediaFiles: [],
    mediaType: null,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFilesSelected = useCallback((files: File[]) => {
    setFormData(prev => ({
      ...prev,
      mediaFiles: files,
      mediaType: 'photo',
    }));
  }, []);

  const handleVideoRecorded = useCallback((file: File) => {
    setFormData(prev => ({
      ...prev,
      mediaFiles: [file],
      mediaType: 'video',
    }));
  }, []);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!userId || !teamId) return;

    try {
      const permissions = [
        Permission.read(Role.user(userId)),
        Permission.update(Role.user(userId)),
        Permission.delete(Role.user(userId)),
        Permission.read(Role.team(teamId)),
        Permission.update(Role.team(teamId)),
        Permission.delete(Role.team(teamId)),
      ];

      await addDamage(
        processId,
        {
          title: formData.title || 'Schaden',
          description: formData.description,
          files: formData.mediaType === 'photo' ? formData.mediaFiles : [],
          video: formData.mediaType === 'video' ? formData.mediaFiles[0] : null
        },
        permissions
      );

      onSuccess();
    } catch (error) {
      console.error('Error creating damage:', error);
    }
  };

  const renderMediaSection = () => {
    if (isMobile) {
      if (!formData.mediaType) {
        return (
          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => setFormData(prev => ({ ...prev, mediaType: 'photo' }))}
              className="flex-1 flex items-center justify-center gap-2 p-4 bg-gray-100 rounded-lg text-gray-700"
            >
              <Camera className="w-5 h-5" />
              Fotos aufnehmen
            </button>
            <button
              type="button"
              onClick={() => setFormData(prev => ({ ...prev, mediaType: 'video' }))}
              className="flex-1 flex items-center justify-center gap-2 p-4 bg-gray-100 rounded-lg text-gray-700"
            >
              <Video className="w-5 h-5" />
              Video aufnehmen
            </button>
          </div>
        );
      }

      if (formData.mediaType === 'photo') {
        return (
          <CameraCapture
            onImageCaptured={handleFilesSelected}
            onImagesSelected={handleFilesSelected}
          />
        );
      }

      if (formData.mediaType === 'video') {
        return <VideoCapture onVideoRecorded={handleVideoRecorded} />;
      }
    } else {
      // Desktop view - always show dropzone
      return (
        <>
          <DesktopDropzone onDrop={handleFilesSelected} />
          {formData.mediaFiles.length > 0 && (
            <div className="mt-4 grid grid-cols-3 gap-2">
              {formData.mediaFiles.map((file, index) => (
                <div key={index} className="relative aspect-square">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Upload ${index + 1}`}
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setFormData(prev => ({
                        ...prev,
                        mediaFiles: prev.mediaFiles.filter((_, i) => i !== index)
                      }));
                    }}
                    className="absolute top-1 right-1 p-1 bg-red-500 rounded-full text-white shadow-lg"
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          )}
        </>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col h-full bg-white p-2 mb-4 shadow rounded">
      <div className="flex items-center justify-between px-1 py-3">
        <div>
          {damages.length > 0 && (
            <button
              type="button"
              onClick={onCancel}
              disabled={isLoading}
              className="text-gray-600 text-xs ps-2"
            >
              Abbrechen
            </button>
          )}
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="inline-flex items-center px-4 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-full disabled:opacity-50"
        >
          {isLoading ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
              Speichern...
            </>
          ) : (
            'Speichern'
          )}
        </button>
      </div>

      <div className="flex-1">
        {error && (
          <div className="bg-red-50 border-b border-red-200 text-red-700 px-4 py-2 text-sm">
            {error}
          </div>
        )}

        {renderMediaSection()}

        <div className="px-0 pt-4 space-y-3">
          <div className="space-y-1">
            <input
              name="title"
              type="text"
              value={formData.title}
              onChange={handleInputChange}
              disabled={isLoading}
              className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:bg-gray-50"
              placeholder="Titel..."
            />
          </div>

          <div className="space-y-1">
            <input
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              disabled={isLoading}
              className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:bg-gray-50"
              placeholder="Beschreibung..."
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddDamageForm;
