import React from 'react';
import { View, Text } from '@react-pdf/renderer';

interface BikeInfoProps {
  bike: {
    name?: string;
    manufacturer?: string;
    model?: string;
    frameNumber?: string;
    frameType?: string;
    color?: string;
    type?: string;
    gears?: string;
    tireSize?: string;
    mudguards?: string;
    rackType?: string;
    transmission?: string;
  };
}

export const BikeInfo: React.FC<BikeInfoProps> = ({ bike }) => {
  const createInfoItem = (label: string, value?: string) => {
    if (!value) return null;
    return (
      <View
        style={{
          marginBottom: 6,
          marginRight: 12,
          width: '23%',
        }}
      >
        <Text
          style={{
            fontSize: 7,
            color: '#64748b',
            textTransform: 'uppercase',
            letterSpacing: 0.3,
          }}
        >
          {label}
        </Text>
        <Text
          style={{
            fontSize: 9,
            color: '#0f172a',
            fontWeight: 'bold',
          }}
        >
          {value}
        </Text>
      </View>
    );
  };

  return (
    <View
      style={{
        marginTop: 10,
        marginBottom: 10,
        padding: 12,
        backgroundColor: '#f8fafc',
      }}
    >
      <Text
        style={{
          fontSize: 10,
          fontWeight: 'bold',
          color: '#0ea5e9',
          marginBottom: 8,
          textTransform: 'uppercase',
          letterSpacing: 0.5,
        }}
      >
        Fahrraddaten
      </Text>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
        }}
      >
        {createInfoItem('Bezeichnung', bike.name)}
        {createInfoItem('Hersteller', bike.manufacturer)}
        {createInfoItem('Modell', bike.model)}
        {createInfoItem('Rahmennummer', bike.frameNumber)}
        {createInfoItem('Rahmentyp', bike.frameType)}
        {createInfoItem('Farbe', bike.color)}
        {createInfoItem('Fahrradtyp', bike.type)}
        {createInfoItem('Reifengröße', bike.tireSize)}
      </View>
    </View>
  );
};
