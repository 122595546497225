import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { client } from '../utils/appwrite';
import { Account } from 'appwrite';
import { useAuth } from '../contexts/AuthContext';

type VerificationStatus =
  | 'verifying'
  | 'success'
  | 'error'
  | 'await_verification';
type SendEmailStatus = 'idle' | 'sending' | 'sent' | 'error';

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<VerificationStatus>('verifying');
  const [sendEmailStatus, setSendEmailStatus] =
    useState<SendEmailStatus>('idle');
  const navigate = useNavigate();
  const { session, emailVerification, checkVerification } = useAuth();

  const sendVerificationEmail = async () => {
    console.log('Sending verification email in VerifyEmail');
    try {
      setSendEmailStatus('sending');
      const users = new Account(client);
      await users.createVerification(`${window.location.origin}/verify-email`);
      setSendEmailStatus('sent');
    } catch (error) {
      console.error('Error sending verification email:', error);
      setSendEmailStatus('error');
    }
  };

  useEffect(() => {
    const userId = searchParams.get('userId');
    const secret = searchParams.get('secret');

    // If no verification parameters, just show the request verification view
    if (!userId || !secret) {
      setStatus('await_verification');
      return;
    }

    // Only attempt verification if we have both parameters
    const verifyEmail = async () => {
      try {
        const users = new Account(client);
        await users.updateVerification(userId, secret);
        await checkVerification(); // Update the auth context
        setStatus('success');
        // Redirect after a short delay
        setTimeout(() => navigate('/dashboard'), 3000);
      } catch (error) {
        console.error('Verification error:', error);
        setStatus('error');
      }
    };

    verifyEmail();
  }, [searchParams, navigate, checkVerification]);

  // Redirect if already verified
  useEffect(() => {
    if (emailVerification) {
      navigate('/dashboard');
    }
  }, [emailVerification, navigate]);

  // Redirect if not logged in
  if (!session) {
    navigate('/login');
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {status === 'verifying' && (
            <div className="text-center">
              <h2 className="text-xl font-semibold">
                E-Mail wird verifiziert...
              </h2>
              <p className="mt-2 text-gray-600">Einen Moment bitte.</p>
            </div>
          )}

          {status === 'success' && (
            <div className="text-center">
              <h2 className="text-xl font-semibold text-green-600">
                E-Mail erfolgreich verifiziert!
              </h2>
              <p className="mt-2 text-gray-600">
                Sie werden zum Dashboard weitergeleitet...
              </p>
            </div>
          )}

          {status === 'error' && (
            <div className="text-center">
              <h2 className="text-xl font-semibold text-red-600">
                Verifizierung fehlgeschlagen
              </h2>
              <p className="mt-2 text-gray-600">
                Der Verifizierungslink ist ungültig oder abgelaufen.
              </p>
              <button
                onClick={() => {
                  setStatus('await_verification');
                  setSendEmailStatus('idle');
                }}
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Neuen Verifizierungslink anfordern
              </button>
            </div>
          )}

          {status === 'await_verification' && (
            <div className="text-center space-y-4">
              <h2 className="text-xl font-semibold">
                E-Mail-Verifizierung erforderlich
              </h2>
              <p className="text-gray-600">
                Bitte klicken Sie auf den Button unten, um einen
                Verifizierungslink zu erhalten.
              </p>

              {sendEmailStatus === 'idle' && (
                <button
                  onClick={sendVerificationEmail}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Verifizierungslink anfordern
                </button>
              )}

              {sendEmailStatus === 'sending' && (
                <div className="text-gray-600">E-Mail wird gesendet...</div>
              )}

              {sendEmailStatus === 'sent' && (
                <div className="space-y-4">
                  <div className="text-green-600">
                    Verifizierungs-E-Mail wurde gesendet!
                  </div>
                  <p className="text-gray-600">
                    Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf
                    den Verifizierungslink in der E-Mail.
                  </p>
                  <button
                    onClick={sendVerificationEmail}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    E-Mail erneut senden
                  </button>
                </div>
              )}

              {sendEmailStatus === 'error' && (
                <div className="space-y-4">
                  <div className="text-red-600">
                    Fehler beim Senden der E-Mail
                  </div>
                  <button
                    onClick={sendVerificationEmail}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Erneut versuchen
                  </button>
                </div>
              )}

              <div className="mt-4 pt-4 border-t border-gray-200">
                <p className="text-sm text-gray-600 mb-2">
                  Bereits verifiziert?
                </p>
                <button
                  onClick={async () => {
                    await checkVerification();
                    if (emailVerification) {
                      navigate('/dashboard');
                    }
                  }}
                  className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Prüfen und zum Dashboard
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
