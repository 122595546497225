import React from 'react';
import { View, Text, Svg, Path } from '@react-pdf/renderer';
import { styles } from './styles';
import { Process } from '../../types/process';
import { Workshop } from '../../types/workshop';
import { Bike } from '../../types/bike';
import { Logo } from './Logo';

interface CoverPageProps {
  process: Process;
  workshop: Workshop;
  bike: Bike;
}

export const CoverPage: React.FC<CoverPageProps> = ({
  process,
  workshop,
  bike,
}) => (
  <View style={styles.coverPage}>
    <View style={styles.letterhead}>
      <View style={styles.workshopInfo}>
        <Text style={styles.workshopName}>{workshop.name}</Text>
        <Text style={styles.workshopAddress}>
          {workshop.streetAddress}
          {'\n'}
          {workshop.postalCode} {workshop.city}
          {'\n'}
          Tel: {workshop.phoneNumber}
        </Text>
      </View>
      <View style={styles.logo}>
        <Logo workshop={workshop} />
      </View>
    </View>

    <View style={styles.coverContent}>
      <Text style={styles.documentType}>Schadensgutachten</Text>
      <Text style={styles.bikeId}>{bike.frameNumber}</Text>
      <View style={styles.coverDivider} />

      <View style={styles.coverDetails}>
        <View style={styles.detailRow}>
          <Text style={styles.detailLabel}>Datum</Text>
          <Text style={styles.detailValue}>
            {new Date(process.created).toLocaleDateString('de-DE')}
          </Text>
        </View>
        <View style={styles.detailRow}>
          <Text style={styles.detailLabel}>Vorgangsnummer</Text>
          <Text style={styles.detailValue}>{process.serialnumber}</Text>
        </View>
        {/*           
        <View style={styles.detailRow}>
          <Text style={styles.detailLabel}>Gutachter ADD ME </Text>
<Text style={styles.detailValue}>{process.mechanic}</Text> 
        </View>
*/}
      </View>
    </View>
  </View>
);
