import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Video, X, Square, Loader2 } from 'lucide-react';
import Webcam from 'react-webcam';

interface VideoCaptureProps {
  onVideoRecorded: (file: File) => void;
}

const MAX_RECORDING_DURATION = 30; // Maximum recording duration in seconds

const VideoCapture: React.FC<VideoCaptureProps> = ({ onVideoRecorded }) => {
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [remainingTime, setRemainingTime] = useState(MAX_RECORDING_DURATION);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const timerRef = useRef<NodeJS.Timeout>();

  // Helper function to check supported MIME types
  const getSupportedMimeType = () => {
    const types = [
      'video/webm;codecs=vp8,opus',
      'video/webm;codecs=vp8',
      'video/webm',
      'video/mp4'
    ];

    return types.find(type => MediaRecorder.isTypeSupported(type)) || '';
  };

  const videoConstraints = {
    width: { ideal: 820 },
    height: { ideal: 460 },
    facingMode: { ideal: 'environment' },
    frameRate: { ideal: 30 }
  };

  const handleCameraStart = useCallback(() => {
    setErrorMessage(null);
    setIsCameraReady(true);
  }, []);

  const handleCameraError = useCallback((err: string | DOMException) => {
    console.error('Webcam error:', err);
    setErrorMessage('Kamerazugriff fehlgeschlagen');
    setIsCameraReady(false);
  }, []);

  // Modified process video function
  const processVideo = async (blob: Blob): Promise<File> => {
    try {
      const url = URL.createObjectURL(blob);
      const video = document.createElement('video');
      await new Promise((resolve, reject) => {
        video.onloadedmetadata = resolve;
        video.onerror = reject;
        video.src = url;
      });

      URL.revokeObjectURL(url);

      const timestamp = Date.now();
      const mimeType = blob.type || 'video/webm';
      const extension = mimeType.includes('mp4') ? 'mp4' : 'webm';

      return new File([blob], `video-${timestamp}.${extension}`, {
        type: mimeType,
        lastModified: timestamp
      });
    } catch (error) {
      console.error('Error processing video:', error);
      throw new Error('Invalid video format');
    }
  };

  const startRecording = useCallback(() => {
    setErrorMessage(null);
    setIsProcessing(false);
    setPreviewUrl(null);
    setRemainingTime(MAX_RECORDING_DURATION);
    chunksRef.current = [];

    const stream = webcamRef.current?.video?.srcObject as MediaStream;
    if (!stream) {
      setErrorMessage('Kein Kamerazugriff');
      return;
    }

    try {
      const mimeType = getSupportedMimeType();
      if (!mimeType) {
        throw new Error('No supported video format found');
      }

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType,
        videoBitsPerSecond: 2500000
      });

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start(1000);
      setIsRecording(true);

      // Start countdown timer
      timerRef.current = setInterval(() => {
        setRemainingTime(prev => {
          if (prev <= 1) {
            stopRecording();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      // Automatically stop recording after MAX_RECORDING_DURATION
      setTimeout(() => {
        if (mediaRecorderRef.current?.state === 'recording') {
          stopRecording();
        }
      }, MAX_RECORDING_DURATION * 1000);

    } catch (error) {
      console.error('Error starting recording:', error);
      setErrorMessage('Fehler beim Starten der Aufnahme');
    }
  }, []);

  const stopRecording = useCallback(async () => {
    if (!mediaRecorderRef.current || mediaRecorderRef.current.state !== 'recording') return;

    try {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsProcessing(true);

      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      // Wait for the last chunk
      await new Promise(resolve => setTimeout(resolve, 200));

      const mimeType = mediaRecorderRef.current.mimeType;
      const recordedBlob = new Blob(chunksRef.current, { type: mimeType });

      // Process and validate the video
      const processedVideo = await processVideo(recordedBlob);

      // Verify the file size
      if (processedVideo.size === 0) {
        throw new Error('Recorded video is empty');
      }

      // Create and verify preview
      const newPreviewUrl = URL.createObjectURL(processedVideo);
      const previewVideo = document.createElement('video');

      await new Promise((resolve, reject) => {
        previewVideo.onloadedmetadata = resolve;
        previewVideo.onerror = reject;
        previewVideo.src = newPreviewUrl;
      });

      setPreviewUrl(newPreviewUrl);
      onVideoRecorded(processedVideo);
      setIsProcessing(false);

    } catch (error) {
      console.error('Error processing video:', error);
      setErrorMessage('Fehler bei der Videoverarbeitung');
      setIsProcessing(false);
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl(null);
      }
    }
  }, [onVideoRecorded]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const formatTime = (seconds: number): string => {
    return `0:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        {!previewUrl ? (
          <Webcam
            ref={webcamRef}
            audio={true}
            muted={true} // Mute live preview to prevent echo
            videoConstraints={videoConstraints}
            onUserMedia={handleCameraStart}
            onUserMediaError={handleCameraError}
            className="w-full rounded-lg"
          />
        ) : (
          <video
            src={previewUrl}
            controls
            playsInline
            className="w-full rounded-lg"
          />
        )}

        {/* Recording indicator with countdown */}
        {isRecording && (
          <div className="absolute top-4 left-4 flex items-center gap-2 bg-black/50 px-3 py-1 rounded-full text-white">
            <div className="w-2 h-2 rounded-full bg-red-500 animate-pulse" />
            {formatTime(remainingTime)}
          </div>
        )}

        {/* Control buttons */}
        <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-4">
          {!isRecording && !isProcessing && !previewUrl && (
            <button
              type="button"
              onClick={startRecording}
              disabled={!isCameraReady}
              className="p-3 bg-red-500 rounded-full text-white shadow-lg disabled:opacity-50"
            >
              <Video className="w-6 h-6" />
            </button>
          )}

          {isRecording && (
            <button
              type="button"
              onClick={stopRecording}
              className="p-3 bg-red-500 rounded-full text-white shadow-lg"
            >
              <Square className="w-6 h-6" />
            </button>
          )}

          {previewUrl && (
            <button
              type="button"
              onClick={() => {
                setPreviewUrl(null);
                startRecording();
              }}
              className="p-3 bg-blue-500 rounded-full text-white shadow-lg"
            >
              <X className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>

      {/* Status messages */}
      {errorMessage && (
        <div className="text-red-500 text-sm text-center">{errorMessage}</div>
      )}

      {isProcessing && (
        <div className="flex items-center justify-center gap-2 text-blue-500 text-sm">
          <Loader2 className="w-4 h-4 animate-spin" />
          Video wird verarbeitet...
        </div>
      )}
    </div>
  );
};

export default VideoCapture;
