import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account } from 'appwrite';
import { client } from '../utils/appwrite';
import { useAuth } from '../contexts/AuthContext';

export default function Logout(): JSX.Element {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const performLogout = async (): Promise<void> => {
      try {
        const account = new Account(client);

        // Delete all sessions
        await account.deleteSessions();

        // Clear auth context
        logout();

        // Clear any local storage
        localStorage.clear();

        // Redirect to login
        navigate('/login', { replace: true });
      } catch (error) {
        console.error('Logout error:', error);
        // Ensure logout even on error
        logout();
        localStorage.clear();
        navigate('/login', { replace: true });
      }
    };

    performLogout();
  }, [navigate, logout]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="text-center">
            <h2 className="text-xl font-semibold">Abmelden...</h2>
            <p className="mt-2 text-gray-600">Sie werden abgemeldet.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
