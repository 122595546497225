import { useState } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { databases } from '../../../utils/appwrite';
import { BikeInspectionData } from '../../../types/process';
import { CustomerInfoStep } from '../common/steps/CustomerInfoStep';
/*
import { BikeInfoStep } from './steps/BikeInfoStep';
import { FrameInspectionStep } from './steps/FrameInspectionStep';
import { HandlebarsStep } from './steps/HandlebarsStep';
import { WheelsStep } from './steps/WheelsStep';
import { BrakesStep } from './steps/BrakesStep';
import { TestRideStep } from './steps/TestRideStep';
import { FinalReviewStep } from './steps/FinalReviewStep';
*/

interface InspectionSmallProps {
  onComplete: (data: BikeInspectionData) => void;
  onCancel: () => void;
}

export const InspectionSmall: React.FC<InspectionSmallProps> = ({
  onComplete,
  onCancel,
}) => {
  const [inspectionData, setInspectionData] = useState<BikeInspectionData>({
    customerInfo: { name: '', maxBudget: 0 },
    bikeInfo: { model: '', identNumber: '', orderNumber: '', mileage: 0 },
    inspection: {
      frame: { frameAndForkChecked: false },
      handlebar: {
        headsetChecked: false,
        handlebarTightened: false,
        handlebarsChecked: false,
        bellChecked: false,
      },
      wheels: {
        mountingChecked: false,
        tiresChecked: false,
        rimsChecked: false,
      },
      brakes: {
        leverChecked: false,
        padsChecked: false,
        cablesChecked: false,
        hydraulicsChecked: false,
      },
      testRide: { completed: false },
    },
    mechanicNotes: '',
    stvzoCompliant: false,
  });

  const handleUpdate = (updates: Partial<BikeInspectionData>) => {
    setInspectionData((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  const dummyData: BikeInspectionData = {
    customerInfo: {
      name: 'Max Mustermann',
      maxBudget: 500,
    },
    bikeInfo: {
      model: 'Canyon Exceed',
      identNumber: 'M123456',
      orderNumber: 'O789012',
      mileage: 1500,
    },
    inspection: {
      frame: { frameAndForkChecked: false },
      handlebar: {
        headsetChecked: false,
        handlebarTightened: false,
        handlebarsChecked: false,
        bellChecked: false,
      },
      wheels: {
        mountingChecked: false,
        tiresChecked: false,
        rimsChecked: false,
      },
      brakes: {
        leverChecked: false,
        padsChecked: false,
        cablesChecked: false,
        hydraulicsChecked: false,
      },
      testRide: { completed: false },
    },
    mechanicNotes: '',
    stvzoCompliant: false,
  };

  return (
    <div className="max-w-2xl mx-auto p-4 bg-white rounded-lg shadow">
      <Wizard>
        <CustomerInfoStep data={dummyData.customerInfo} onUpdate={() => {}} />
        {/*
        <WizardHeader />
        <BikeInfoStep
          data={inspectionData}
          onUpdate={handleUpdate}
        />
        <FrameInspectionStep
          data={inspectionData}
          onUpdate={handleUpdate}
        />
        <HandlebarsStep
          data={inspectionData}
          onUpdate={handleUpdate}
        />
        <WheelsStep
          data={inspectionData}
          onUpdate={handleUpdate}
        />
        <BrakesStep
          data={inspectionData}
          onUpdate={handleUpdate}
        />
        <TestRideStep
          data={inspectionData}
          onUpdate={handleUpdate}
        />
        <FinalReviewStep
          data={inspectionData}
          onComplete={onComplete}
          onCancel={onCancel}
        />
*/}
      </Wizard>
    </div>
  );
};
