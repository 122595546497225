import React from 'react';
import { View, Text, Image, Link } from '@react-pdf/renderer';
import { Storage } from 'appwrite';
import { client } from '../../utils/appwrite';
import { Damage } from '../../types/damage';

const storage = new Storage(client);

interface DamageReportProps {
  damage: Damage;
}

const MAX_DESCRIPTION_HEIGHT = 400;
const APPROX_LINE_HEIGHT = 20;

const shouldBreakContent = (description: string) => {
  const approximateLines = Math.ceil(description.length / 80);
  const approximateHeight = approximateLines * APPROX_LINE_HEIGHT;
  return approximateHeight > MAX_DESCRIPTION_HEIGHT;
};

const DamageHeader: React.FC<{ title: string }> = ({ title }) => (
  <View style={{
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    borderBottom: 1,
    borderBottomColor: '#e2e8f0',
    paddingBottom: 12,
  }}>
    <View style={{
      backgroundColor: '#f1f5f9',
      padding: '4 8',
      borderRadius: 4,
      marginRight: 12,
    }}>
      <Text style={{
        fontSize: 10,
        color: '#64748b',
        fontWeight: 'bold',
      }}>
        Schaden
      </Text>
    </View>
    <Text style={{
      fontSize: 14,
      fontWeight: 'bold',
      color: '#0f172a',
    }}>
      {title}
    </Text>
  </View>
);

const ImageSection: React.FC<{ images: string[] }> = ({ images }) => {
  const maxWidth = 450;
  const getImageConfig = () => {
    if (images.length === 1) return { width: maxWidth, height: 300 };
    if (images.length === 2) return { width: maxWidth / 2 - 8, height: 240 };
    if (images.length <= 4) return { width: maxWidth / 2 - 8, height: 180 };
    return { width: maxWidth / 3 - 8, height: 160 };
  };
  const imageConfig = getImageConfig();

  return (
    <View wrap={false}>
      <Text style={{
        fontSize: 10,
        color: '#64748b',
        marginBottom: 12,
        fontWeight: 'bold',
      }}>
        Fotodokumentation
      </Text>
      <View style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 16,
        justifyContent: 'flex-start',
      }}>
        {images.map((imageId, index) => (
          <View
            key={index}
            style={{
              width: imageConfig.width,
              height: imageConfig.height,
              backgroundColor: '#f8fafc',
              borderRadius: 4,
              overflow: 'hidden',
              border: 1,
              borderColor: '#e2e8f0',
            }}
          >
            <Image
              src={storage.getFilePreview('6719a1030025fdcdfecc', imageId, 800).toString()}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                backgroundColor: '#f8fafc',
              }}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

export const DamageReport: React.FC<DamageReportProps> = ({ damage }) => {
  const hasImages = damage.images && damage.images.length > 0;
  const hasVideo = damage.videoUUID;
  const needsBreak = shouldBreakContent(damage.description);

  return (
    <View style={{
      padding: 20,
      backgroundColor: '#ffffff',
    }}>
      <View wrap={false}>
        <DamageHeader title={damage.title} />

        <Text style={{
          fontSize: 11,
          color: '#334155',
          lineHeight: 1.6,
          marginBottom: 16,
        }}>
          {damage.description}
        </Text>

        {hasVideo && (
          <View style={{
            alignItems: 'center',
            marginBottom: hasImages ? 24 : 0,
          }}>
            <Link
              src={`https://app.bikecheck-pro.de/v/${damage.videoUUID}`}
              style={{
                backgroundColor: '#2563eb',
                paddingVertical: 12,
                paddingHorizontal: 24,
                borderRadius: 6,
                textDecoration: 'none',
              }}
            >
              <Text style={{
                fontSize: 12,
                color: '#ffffff',
                textAlign: 'center',
              }}>
                🎥 Videodokumentation ansehen
              </Text>
            </Link>
          </View>
        )}
      </View>

      {hasImages && (
        <View wrap={false} style={{ marginTop: needsBreak ? 0 : 24 }}>
          <ImageSection images={damage.images} />
        </View>
      )}
    </View>
  );
};
