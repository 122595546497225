import { useShallow } from 'zustand/react/shallow';
import React, { useState } from 'react';
import { Storage } from 'appwrite';
import { client } from '../../../utils/appwrite';
import EditDamageModal from './EditDamageModal';
import DeleteConfirmModal from '../../DeleteConfirmModal';
import { Damage } from '../../../types/damage';
import DamageAIAnalysis from './DamageAIAnalysis';
import { useProcessStore } from '../../../stores/processStore';

const storage = new Storage(client);

const DamageList: React.FC = () => {
  const damages = useProcessStore((state) => state.damages);
  const updateDamage = useProcessStore((state) => state.updateDamage);
  const deleteDamage = useProcessStore((state) => state.deleteDamage);
  const isLoading = useProcessStore((state) => state.isDamagesLoading);

  const [editingDamage, setEditingDamage] = useState<Damage | null>(null);
  const [deletingDamageId, setDeletingDamageId] = useState<string | null>(null);

  const getImagePreviewUrl = (fileId: string) => {
    return storage.getFilePreview('6719a1030025fdcdfecc', fileId);
  };

  const getVideoUrl = (fileId: string) => {
    return storage.getFileView('damage-videos', fileId);
  };

  const handleEditClick = (damage: Damage) => {
    setEditingDamage(damage);
  };

  const handleDeleteClick = (damageId: string) => {
    setDeletingDamageId(damageId);
  };

  if (isLoading) {
    return <div className="text-center py-4">Lädt Schäden...</div>;
  }

  return (
    <div className="space-y-2">
      {[...damages].reverse().map((damage) => (
        <div key={damage.$id} className="bg-white p-4 rounded-lg shadow-lg border">
          <h3 className="font-medium text-lg">{damage.title}</h3>
          <p className="text-gray-600 mt-2">{damage.description}</p>

          {/* Video Section */}
          {damage.video && damage.videoUUID && (
            <div className="mt-4">
              <a
                href={`/v/${damage.videoUUID}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-lg hover:bg-blue-100"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Video ansehen
              </a>
            </div>
          )}

          {/* Images Section */}
          {damage.images && damage.images.length > 0 && (
            <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {damage.images.map((imageId) => (
                <div
                  key={imageId}
                  className="relative pb-[100%] overflow-hidden rounded-lg"
                >
                  <img
                    src={getImagePreviewUrl(imageId)}
                    alt="Damage"
                    className="absolute inset-0 w-full h-full object-contain bg-gray-100"
                  />
                </div>
              ))}
            </div>
          )}

          {/* AI Analysis Component */}
          {damage.images && damage.images.length > 0 && (
            <DamageAIAnalysis
              imageId={damage.images[0]}
              damageId={damage.$id}
              currentDescription={damage.description}
              currentTitle={damage.title}
              onUpdateDamage={(updates) => {
                const updatedDamage = {
                  ...damage,
                  ...updates,
                };
                updateDamage(updatedDamage.$id, updatedDamage);
              }}
            />
          )}

          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={() => handleEditClick(damage)}
              className="px-3 py-1 rounded bg-blue-100 hover:bg-blue-200 text-blue-800"
            >
              Bearbeiten
            </button>
            <button
              onClick={() => handleDeleteClick(damage.$id)}
              className="px-3 py-1 rounded bg-red-100 hover:bg-red-200 text-red-800"
            >
              Löschen
            </button>
          </div>
        </div>
      ))}

      {damages.length === 0 && !isLoading && (
        <p className="text-gray-500 text-center py-4">
          Noch keine Schäden dokumentiert.
        </p>
      )}

      {/* Modals */}
      {editingDamage && (
        <EditDamageModal
          damage={editingDamage}
          onClose={() => setEditingDamage(null)}
          onSave={async (updatedDamage) => {
            await updateDamage(updatedDamage.$id, updatedDamage);
            setEditingDamage(null);
          }}
        />
      )}

      {deletingDamageId && (
        <DeleteConfirmModal
          isOpen={!!deletingDamageId}
          onClose={() => setDeletingDamageId(null)}
          onConfirm={async () => {
            const damage = damages.find(d => d.$id === deletingDamageId);
            await deleteDamage(
              deletingDamageId,
              damage?.images || [],
              damage?.video // Pass the video ID for deletion
            );
            setDeletingDamageId(null);
          }}
        />
      )}
    </div>
  );
};

export default DamageList;
