import React, { useState } from 'react';
import { Functions } from 'appwrite';
import { client } from '../../../utils/appwrite';
import { Loader2, Zap } from 'lucide-react';

const functions = new Functions(client);

interface DamageAIAnalysisProps {
  imageId: string;
  damageId: string;
  currentDescription: string;
  currentTitle: string;
  onUpdateDamage: (updates: { description: string; title?: string }) => void;
}

const DamageAIAnalysis: React.FC<DamageAIAnalysisProps> = ({
  imageId,
  damageId,
  currentDescription,
  currentTitle,
  onUpdateDamage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [aiAnalysis, setAiAnalysis] = useState<string | null>(null);

  const analyzeImage = async () => {
    setIsLoading(true);
    try {
      const execution = await functions.createExecution(
        '6731f8380018e7fc8474',
        JSON.stringify({
          fileId: imageId,
          damageId: damageId,
        })
      );

      const responseData = JSON.parse(execution.responseBody);

      if (!responseData.success) {
        throw new Error(responseData.error || 'Bildanalyse fehlgeschlagen');
      }

      setAiAnalysis(responseData.text);
    } catch (error) {
      console.error('Analysefehler:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAppendDescription = () => {
    if (!aiAnalysis) return;

    const updatedDescription = currentDescription
      ? `${currentDescription}\n\n--- KI-Analyse ---\n${aiAnalysis}`
      : aiAnalysis;

    const updates: { description: string; title?: string } = {
      description: updatedDescription,
    };

    // If no title exists, use first two lines of AI analysis
    if (!currentTitle.trim()) {
      const lines = aiAnalysis.split('\n');
      if (lines.length >= 2) {
        const newTitle = lines.slice(0, 2).join(' - ').trim();
        updates.title = newTitle;
      }
    }
    setAiAnalysis(null);

    onUpdateDamage(updates);
  };

  return (
    <div className="mt-4 bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-3 flex items-center justify-between">
        <h3 className="text-sm font-medium text-gray-700">KI Analyse</h3>
        <div className="flex items-center space-x-2">
          {!aiAnalysis && (
            <button
              onClick={analyzeImage}
              disabled={isLoading || !imageId}
              className={`p-2 rounded-full text-white ${isLoading || !imageId
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600'
                }`}
              aria-label="Bild analysieren"
            >
              {isLoading ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                <Zap className="h-5 w-5" />
              )}
            </button>
          )}
          {aiAnalysis && (
            <button
              onClick={handleAppendDescription}
              className="text-xs text-blue-600 hover:text-blue-700 font-medium"
            >
              Hinzufügen
            </button>
          )}
        </div>
      </div>
      {aiAnalysis && (
        <div className="px-3 pb-3">
          <p className="text-sm text-gray-600 bg-gray-50 p-2 rounded border border-gray-200 max-h-24 overflow-y-auto">
            {aiAnalysis}
          </p>
        </div>
      )}
    </div>
  );
};

export default DamageAIAnalysis;
