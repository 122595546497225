import React, { useState } from 'react';
import { ID, Permission, Role, Storage } from 'appwrite';
import { client } from '../../../utils/appwrite';
import { useAuth } from '../../../contexts/AuthContext';
import { Loader2, Upload, Trash2, FileText } from 'lucide-react';
import DeleteConfirmModal from '../../reusable/DeleteConfirmModal';
import { useProcessStore } from '../../../stores/processStore';

const storage = new Storage(client);

interface QuoteManagementProps {
  processId: string;
  quoteFileId?: string;
  quoteName?: string;
}

const QuoteManagement = ({
  processId,
  quoteFileId,
  quoteName,
}: QuoteManagementProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { userId, teamId } = useAuth();
  const { updateProcessQuote } = useProcessStore();

  if (!userId) return null;

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsLoading(true);
    try {
      if (quoteFileId) {
        await storage.deleteFile('Quote', quoteFileId);
      }

      const fileId = ID.unique();
      await storage.createFile('Quote', fileId, file, [
        Permission.read(Role.user(userId)),
        Permission.update(Role.user(userId)),
        Permission.delete(Role.user(userId)),
        Permission.read(Role.team(teamId!)),
        Permission.update(Role.team(teamId!)),
        Permission.delete(Role.team(teamId!)),
      ]);

      await updateProcessQuote(processId, fileId, file.name);
    } catch (error) {
      console.error('Error uploading quote:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!quoteFileId) return;

    setIsLoading(true);
    try {
      await storage.deleteFile('Quote', quoteFileId);
      await updateProcessQuote(processId, null, null);
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting quote:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    if (!quoteFileId) return;
    const result = storage.getFileView('Quote', quoteFileId);
    window.open(result.toString(), '_blank');
  };

  const hasQuote = Boolean(quoteFileId && quoteName);

  return (
    <div className="border-t pt-6">
      {isLoading ? (
        <div className="flex items-center space-x-2 text-gray-600">
          <Loader2 className="w-4 h-4 animate-spin" />
          <span>Wird verarbeitet...</span>
        </div>
      ) : (
        <div className="space-y-4">
          {hasQuote && (
            <div className="flex items-center space-x-4 p-3 bg-gray-50 rounded-lg">
              <button
                onClick={handleDownload}
                className="flex items-center space-x-2 text-blue-600 hover:text-blue-700"
              >
                <FileText className="w-4 h-4" />
                <span className="truncate max-w-xs">{quoteName}</span>
              </button>
              <button
                onClick={() => setShowDeleteModal(true)}
                className="flex items-center space-x-2 text-red-600 hover:text-red-700"
                title="Datei löschen"
              >
                <Trash2 className="w-4 h-4" />
                <span className="sr-only">Löschen</span>
              </button>
            </div>
          )}

          <div className="relative">
            <input
              type="file"
              onChange={handleFileUpload}
              accept="application/pdf"
              className="hidden"
              id="quote-upload"
            />
            <label
              htmlFor="quote-upload"
              className="flex items-center space-x-2 text-blue-600 hover:text-blue-700 cursor-pointer"
            >
              <Upload className="w-4 h-4" />
              <span>{hasQuote ? 'Neue PDF hochladen' : 'PDF hochladen'}</span>
            </label>
          </div>
        </div>
      )}

      <DeleteConfirmModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        title="Kostenvoranschlag löschen"
        message={`Möchten Sie den Kostenvoranschlag "${quoteName}" wirklich löschen?`}
      />
    </div>
  );
};

export default QuoteManagement;
