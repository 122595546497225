import { useLocation, useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Check if it's an unauthorized error based on the path
  const isUnauthorized = location.pathname === '/unauthorized';

  const title = isUnauthorized ? 'Keine Berechtigung' : 'Seite nicht gefunden';

  const message = isUnauthorized
    ? 'Sie haben keine Berechtigung, auf diese Seite zuzugreifen.'
    : 'Die von Ihnen gesuchte Seite existiert leider nicht.';

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="text-center">
            <div className="inline-flex h-24 w-24 items-center justify-center rounded-full bg-blue-100 mb-6">
              {isUnauthorized ? (
                <svg
                  className="h-12 w-12 text-blue-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 15v2m0 0v2m0-2h2m-2 0H10m0 0h4m-4-4l6-6m-8 8l-6-6m16 6l-6-6m-2 6h.01M12 12h.01M12 12h.01"
                  />
                </svg>
              ) : (
                <svg
                  className="h-12 w-12 text-blue-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 12h.01M12 14h.01M12 16h.01M12 18h.01M12 20h.01M12 22h.01"
                  />
                </svg>
              )}
            </div>

            <h1 className="text-3xl font-bold text-gray-900 mb-2">{title}</h1>

            <p className="text-gray-500 mb-8">{message}</p>

            <div className="flex flex-col space-y-4">
              <button
                onClick={() => navigate(-1)}
                className="inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Zurück zur vorherigen Seite
              </button>

              <button
                onClick={() => navigate('/dashboard')}
                className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Zum Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
