import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Client, Functions, Storage } from 'appwrite';
import { Bike } from 'lucide-react';

// Initialize Appwrite Client
const client = new Client()
  .setEndpoint('https://cloud.appwrite.io/v1')
  .setProject('67196cd4001a8c44b80f');

const functions = new Functions(client);
const storage = new Storage(client);

interface VideoResponse {
  success: boolean;
  title?: string;
  message?: string;
  videoUrl?: {
    $id: string;
    bucketId: string;
    $createdAt: string;
    name: string;
    signature: string;
    mimeType: string;
  };
}

const SecureVideoViewer: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [videoData, setVideoData] = useState<VideoResponse | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videoError, setVideoError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVideoData = async () => {
      if (!uuid) {
        setVideoData({
          success: false,
          message: 'Keine Video-ID gefunden'
        });
        setIsLoading(false);
        return;
      }

      try {
        const response = await functions.createExecution(
          '6739fa270034b591f2c1',
          JSON.stringify({ uuid }),
          false
        );

        const data: VideoResponse = JSON.parse(response.responseBody);

        if (data.success && data.videoUrl) {
          try {
            const previewUrl = storage.getFileView('damage-videos', data.videoUrl.$id);
            setVideoUrl(previewUrl);
            setVideoData(data);
          } catch (storageError) {
            setVideoError('Fehler beim Laden des Videos aus dem Speicher');
          }
        } else {
          setVideoData(data);
        }
      } catch (error) {
        setVideoData({
          success: false,
          message: 'Fehler beim Laden des Videos'
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideoData();
  }, [uuid]);

  const handleVideoError = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    setVideoError('Fehler bei der Video-Wiedergabe');
  };

  const handleVideoLoaded = () => {
    setVideoError(null);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <img
            src="/logo.png"
            alt="BikeCheck Pro"
            className="w-32 h-auto mb-6"
          />
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Video wird geladen...</p>
        </div>
      </div>
    );
  }

  if (!videoData?.success || !videoUrl) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center max-w-2xl">
          <img
            src="/logo.png"
            alt="BikeCheck Pro"
            className="w-32 h-auto mb-6 mx-auto"
          />
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            Video nicht verfügbar
          </h1>
          <p className="text-gray-600">{videoData?.message || 'Unbekannter Fehler'}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Header Bar */}
      <div className="bg-white shadow-sm flex-none">
        <div className="max-w-4xl mx-auto px-4 py-3 flex items-center justify-between">
          <img
            src="/logo.png"
            alt="BikeCheck Pro"
            className="h-8 w-auto"
          />
          <p className="text-sm text-gray-500">
            Vertrauliche Video-Dokumentation
          </p>
        </div>
      </div>

      {/* Main Content - Now with flex and overflow handling */}
      <div className="flex-1 overflow-y-auto">
        <div className="max-w-4xl mx-auto p-4 flex flex-col h-full">
          <div className="bg-white rounded-lg shadow overflow-hidden flex flex-col max-h-[calc(100vh-12rem)]">
            {/* Video Container with maintained aspect ratio */}
            <div className="relative w-full h-0 pb-[56.25%] bg-black">
              <video
                key={videoUrl}
                src={videoUrl}
                controls
                playsInline
                onError={handleVideoError}
                onLoadedData={handleVideoLoaded}
                className="absolute inset-0 w-full h-full object-contain"
              >
                Ihr Browser unterstützt keine Video-Wiedergabe.
              </video>
              {videoError && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="text-white text-center p-4">
                    <p className="text-lg font-semibold">Wiedergabefehler</p>
                    <p>{videoError}</p>
                  </div>
                </div>
              )}
            </div>

            {/* Info Section */}
            <div className="p-4 flex-none">
              <h1 className="text-lg font-medium text-gray-900">
                {videoData.title || 'Video Dokumentation'}
              </h1>
              <p className="mt-2 text-sm text-gray-500">
                Bitte behandeln Sie diesen Link vertraulich und geben Sie ihn nicht an unbefugte Personen weiter.
              </p>
            </div>
          </div>


          <div className="mt-6 flex-none text-center">
            <a
              href="https://bikecheck-pro.de"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center space-x-2 text-sm text-gray-500 hover:text-gray-700 transition-colors"
            >
              <Bike className="w-4 h-4" />
              <span>bikecheck-pro.de</span>
            </a>
          </div>
        </div>
      </div>
    </div >
  );
};

export default SecureVideoViewer;
