import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Damage } from '../../types/damage';
import { Process } from '../../types/process';

interface OverviewProps {
  damages: Damage[];
  process: Process; // Added process prop to access quote information
}

export const Overview: React.FC<OverviewProps> = ({ damages, process }) => {
  return (
    <View style={{
      marginTop: 30,
      backgroundColor: '#ffffff',
      border: 1,
      borderColor: '#e2e8f0',
      borderRadius: 4,
      padding: 16,
    }}>
      <Text style={{
        fontSize: 14,
        fontWeight: 'bold',
        color: '#0f172a',
        marginBottom: 16,
        paddingBottom: 8,
        borderBottom: 1,
        borderBottomColor: '#e2e8f0',
      }}>
        Übersicht der Schäden
      </Text>

      {/* Damage List */}
      {damages.map((damage, index) => (
        <View
          key={index}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 12,
            paddingBottom: 12,
            borderBottom: index < damages.length - 1 ? 1 : 0,
            borderBottomColor: '#f1f5f9',
          }}
        >
          <Text style={{
            fontSize: 12,
            color: '#0f172a',
            fontWeight: 'bold',
          }}>
            {index + 1}. {damage.title}
          </Text>
          <View style={{
            flexDirection: 'row',
            gap: 8,
          }}>
            {damage.images && damage.images.length > 0 && (
              <Text style={{
                fontSize: 10,
                color: '#64748b',
              }}>
                📷 {damage.images.length} {damage.images.length === 1 ? 'Foto' : 'Fotos'}
              </Text>
            )}
            {damage.videoUUID && (
              <Text style={{
                fontSize: 10,
                color: '#64748b',
              }}>
                🎥 Video
              </Text>
            )}
          </View>
        </View>
      ))}

      {/* Summary Section */}
      <View style={{
        marginTop: 16,
        paddingTop: 16,
        borderTop: 1,
        borderTopColor: '#e2e8f0',
      }}>
        <Text style={{
          fontSize: 12,
          fontWeight: 'bold',
          color: '#0f172a',
          marginBottom: 8,
        }}>
          Anhang
        </Text>

        {/* Attached PDF if exists */}
        {process.quoteFileId && process.quoteName && (
          <Text style={{
            fontSize: 10,
            color: '#64748b',
            marginBottom: 4,
          }}>
            📄 Dokument: {process.quoteName}
          </Text>
        )}

        {/* Summary of photos and videos 
        <Text style={{
          fontSize: 10,
          color: '#64748b',
        }}>
          📷 Fotos: {damages.reduce((total, damage) => total + (damage.images?.length || 0), 0)}
        </Text>
        */}

        {damages.some(damage => damage.videoUUID) && (
          <Text style={{
            fontSize: 10,
            color: '#64748b',
          }}>
            🎥 Videos: {damages.filter(damage => damage.videoUUID).length}
          </Text>
        )}
      </View>
    </View>
  );
};
