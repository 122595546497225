import { CommonCustomerData } from '../../../../types/customer';

interface CustomerInfoStepProps {
  data: CommonCustomerData;
  onUpdate: (data: Partial<CommonCustomerData>) => void;
}

export const CustomerInfoStep: React.FC<CustomerInfoStepProps> = ({
  data,
  onUpdate,
}) => {
  return (
    <>
      <p>Customer Info Step</p>
    </>
  );
};
