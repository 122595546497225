import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { Client, Account, Models, Teams, Query } from 'appwrite';

// Separate interfaces for better organization
interface UserData {
  session: Models.Session | null;
  name: string | null;
  userId: string | null;
  emailVerification: boolean | null;
}

interface TeamData {
  teamId: string | null;
  teamRole: string | null;
}

interface AuthState extends UserData, TeamData {
  loading: boolean;
  error: string | null;
  emailVerification: boolean | null;
}

interface AuthContextType extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setError: (error: string) => void;
  checkVerification: () => Promise<void>;
  sendVerificationEmail: () => Promise<void>;
  isAuthorized: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
  client: Client;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  client,
}) => {
  // Group related state together
  const [userData, setUserData] = useState<UserData>({
    session: null,
    name: null,
    userId: null,
    emailVerification: null,
  });
  const [teamData, setTeamData] = useState<TeamData>({
    teamId: null,
    teamRole: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const account = new Account(client);
  const teams = new Teams(client);

  // console.log('Checking for authorization, verification and team membership');
  const isAuthorized = Boolean(
    userData.session &&
    userData.emailVerification &&
    teamData.teamId &&
    teamData.teamRole
  );

  const fetchTeamData = async (userId: string) => {
    // console.log('Fetching team data for user:', userId);
    try {
      const teamList = await teams.list();
      // console.log(teams);

      if (teamList.teams.length === 0) {
        throw new Error('User is not a member of any team');
      }

      const teamId = teamList.teams[0].$id;
      const memberships = await teams.listMemberships(teamId, [
        Query.equal('userId', [userId]),
      ]);

      if (memberships.memberships.length === 0) {
        throw new Error('Error determining the user role');
      }

      setTeamData({
        teamId,
        teamRole: memberships.memberships[0].roles[0],
      });
    } catch (error) {
      console.error('Error fetching team:', error);
      setTeamData({ teamId: null, teamRole: null });
      throw error;
    }
  };

  const checkVerification = async () => {
    try {
      const user = await account.get();
      setUserData((prev) => ({
        ...prev,
        emailVerification: user.emailVerification,
      }));
    } catch (error) {
      console.error('Error checking verification status:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      const session = await account.getSession('current');
      const user = await account.get();

      setUserData({
        session,
        name: user.name,
        userId: user.$id,
        emailVerification: user.emailVerification, // Add this line
      });

      return user.$id;
    } catch (error) {
      setUserData({
        session: null,
        name: null,
        userId: null,
        emailVerification: null, // Add this line
      });
      throw error;
    }
  };

  const checkSession = async () => {
    try {
      const userId = await fetchUserData();
      if (userId) {
        await fetchTeamData(userId);
      }
    } catch (error) {
      // User is not logged in, which is fine
      console.log('No active session');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add this function
  const sendVerificationEmail = async () => {
    try {
      //     console.log('Sending verification email from auth context');
      setError(null);
      await account.createVerification(
        `${window.location.origin}/verify-email`
      );
    } catch (error: any) {
      //     console.error('Verification email error in Auth Context:', error);
      setError(error?.message || 'Error sending verification email');
      throw error;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      setError(null);
      await account.createEmailPasswordSession(email, password);
      const user = await account.get();

      setUserData({
        session: await account.getSession('current'),
        name: user.name,
        userId: user.$id,
        emailVerification: user.emailVerification,
      });

      // Only fetch team data if email is verified
      if (user.emailVerification) {
        await fetchTeamData(user.$id);
      }
    } catch (error: any) {
      console.error('Login error:', error);
      setError(error?.message || 'Invalid credentials');
      throw error;
    }
  };

  const logout = async () => {
    try {
      await account.deleteSession('current');
      setUserData({
        session: null,
        name: null,
        userId: null,
        emailVerification: null, // Add this line
      });
      setTeamData({ teamId: null, teamRole: null });
      setError(null);
    } catch (error: any) {
      console.error('Logout error:', error);
      setError(error?.message || 'Error during logout');
      throw error;
    }
  };

  const contextValue: AuthContextType = {
    ...userData,
    ...teamData,
    loading,
    error,
    setError,
    login,
    logout,
    checkVerification,
    sendVerificationEmail,
    isAuthorized,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
