import React, { useMemo, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';
import {
  ChevronDown,
  Plus,
  Loader2,
  ChevronLeft,
  ChevronRight,
  ArrowUpDown,
} from 'lucide-react';
import { ViewType } from '../pages/Dashboard';
import { useProcessStore } from '../stores/processStore';
import type { Process, ProcessType } from '../types/process';
import {
  getStatusStyle,
  getStatusText as getStatusLabel,
} from '../utils/processHelpers';
import { useHeaderStore } from '../stores/headerStore';
import EmptyProcessList from './EmptyProcessList';

export const ProcessList: React.FC<{
  onViewChange: (view: ViewType) => void;
  onProcessSelect?: (process: Process) => void;
}> = ({ onViewChange, onProcessSelect }) => {
  const {
    processes,
    isLoading,
    fetchProcesses,
    setCurrentProcess,
    pagination,
    sortOrder,
    setSortOrder,
  } = useProcessStore();

  const { setTitle, setActions } = useHeaderStore();
  const totalPages = Math.ceil(pagination.total / pagination.pageSize);
  const currentPage = pagination.currentPage;


  useEffect(() => {
    setTitle('Vorgänge');

    if (processes.length > 0) {
      setActions([
        {
          icon: <ArrowUpDown className="w-4 h-4" />,
          label: sortOrder === 'desc' ? 'Neueste' : 'Älteste',
          onClick: toggleDateSort,
        },
      ]);
    }

    fetchProcesses();

    return () => {
      setTitle('');
      setActions([]);
    }
  }, [sortOrder, processes.length]);

  const onProcessClick = (process: Process) => {
    setCurrentProcess(process);
    if (onProcessSelect) {
      onProcessSelect(process);
    }
    onViewChange('detail');
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      fetchProcesses(page);
    }
  };

  const toggleDateSort = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  const columns = useMemo<ColumnDef<Process>[]>(
    () => [
      {
        accessorKey: 'serialnumber',
        header: 'Vorgangsnummer',
        cell: (info) => info.getValue() as string,
      },
      {
        accessorKey: 'created',
        header: () => (
          <div
            className="flex items-center cursor-pointer"
            onClick={toggleDateSort}
          >
            <span>DATUM</span>
            <ChevronDown
              className={`w-4 h-4 ml-1 transition-transform duration-200 ${sortOrder === 'asc' ? 'rotate-180' : ''
                }`}
            />
          </div>
        ),
        cell: (info) => {
          const value = info.getValue();
          return value
            ? new Date(value as string).toLocaleDateString('de-DE')
            : '';
        },
      },
      {
        accessorKey: 'type',
        header: 'TYP',
        cell: (info) => {
          const typeLabels = {
            empty: 'Leerer Vorgang',
            'small-inspection': 'Kleine Inspektion',
            'large-inspection': 'Große Inspektion',
            'damage-report': 'Schadensgutachten',
            'maintenance-report': 'Wartungsgutachten',
          };
          return typeLabels[info.getValue() as ProcessType] || info.getValue();
        },
      },
      {
        accessorKey: 'status',
        header: 'STATUS',
        cell: (info) => {
          const status = info.getValue() as number;
          return (
            <span
              className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(status)}`}
            >
              {getStatusLabel(status)}
            </span>
          );
        },
      },
    ],
    [sortOrder, toggleDateSort]
  );

  const table = useReactTable({
    data: processes,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Mobile View */}
      <div className="md:hidden">
        {/* Mobile Cards */}
        <div className="space-y-3 px-0">

          {processes.length !== 0 && !isLoading && (

            table.getRowModel().rows.map((row) => (
              <div
                key={row.id}
                className="bg-white rounded-lg shadow p-4 space-y-1 hover:bg-gray-50 active:bg-gray-100 cursor-pointer"
                onClick={() => onProcessClick(row.original)}
              >
                <div className="font-bold text-gray-900">
                  {row.getValue('serialnumber')}
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div className="text-gray-500">
                    {new Date(row.getValue('created')).toLocaleDateString(
                      'de-DE'
                    )}
                  </div>
                  <div>
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(row.getValue('status'))}`}
                    >
                      {getStatusLabel(row.getValue('status'))}
                    </span>
                  </div>
                </div>
              </div>
            ))
          )}

        </div>
      </div>

      {/* Desktop Table View */}
      <div className="hidden md:block bg-white rounded-lg shadow overflow-hidden">
        {processes.length !== 0 && !isLoading && (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {table.getHeaderGroups().map((headerGroup) =>
                  headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => onProcessClick(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="px-6 py-4 whitespace-nowrap">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination Controls */}
      {processes.length > 0 && (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mb-16 md:mb-0">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center rounded-md px-4 py-2 text-sm font-medium ${currentPage === 1
                ? 'text-gray-300 cursor-not-allowed'
                : 'text-gray-700 hover:bg-gray-50'
                }`}
            >
              Zurück
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center rounded-md px-4 py-2 text-sm font-medium ${currentPage === totalPages
                ? 'text-gray-300 cursor-not-allowed'
                : 'text-gray-700 hover:bg-gray-50'
                }`}
            >
              Weiter
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Zeige{' '}
                <span className="font-medium">
                  {(currentPage - 1) * pagination.pageSize + 1}
                </span>{' '}
                -{' '}
                <span className="font-medium">
                  {Math.min(
                    currentPage * pagination.pageSize,
                    pagination.total
                  )}
                </span>{' '}
                von <span className="font-medium">{pagination.total}</span>{' '}
                Vorgängen
              </p>
            </div>
            <div>
              <nav
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`relative inline-flex items-center rounded-l-md px-2 py-2 ${currentPage === 1
                    ? 'text-gray-300 cursor-not-allowed'
                    : 'text-gray-500 hover:bg-gray-50'
                    }`}
                >
                  <span className="sr-only">Zurück</span>
                  <ChevronLeft className="h-5 w-5" />
                </button>

                {/* Page numbers */}
                {[...Array(totalPages)].map((_, i) => {
                  const pageNum = i + 1;
                  const isCurrentPage = pageNum === currentPage;

                  // Show first page, last page, current page, and pages around current page
                  if (
                    pageNum === 1 ||
                    pageNum === totalPages ||
                    (pageNum >= currentPage - 1 && pageNum <= currentPage + 1)
                  ) {
                    return (
                      <button
                        key={pageNum}
                        onClick={() => handlePageChange(pageNum)}
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${isCurrentPage
                          ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                          : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                          }`}
                      >
                        {pageNum}
                      </button>
                    );
                  } else if (
                    pageNum === currentPage - 2 ||
                    pageNum === currentPage + 2
                  ) {
                    return (
                      <span
                        key={pageNum}
                        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700"
                      >
                        ...
                      </span>
                    );
                  }
                  return null;
                })}

                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`relative inline-flex items-center rounded-r-md px-2 py-2 ${currentPage === totalPages
                    ? 'text-gray-300 cursor-not-allowed'
                    : 'text-gray-500 hover:bg-gray-50'
                    }`}
                >
                  <span className="sr-only">Weiter</span>
                  <ChevronRight className="h-5 w-5" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}

      {/* Empty State */}
      {processes.length === 0 && !isLoading && (
        <div className="mt-40 pt-20 lg:pt-5">
          <EmptyProcessList onViewChange={onViewChange} />
        </div>
      )}

      {processes.length !== 0 && (
        <button
          onClick={() => onViewChange('create')}
          className="fixed right-4 bottom-20 w-14 h-14 bg-blue-600 rounded-full shadow-lg flex items-center justify-center text-white hover:bg-blue-700 active:bg-blue-800 transition-colors duration-200 z-10"
          aria-label="Neuer Vorgang"
        >
          <Plus className="w-6 h-6" />
        </button>
      )}

    </div>
  );
};

export default ProcessList;
