import { useState } from 'react';
import { Settings as SettingsIcon, AlertCircle } from 'lucide-react';
import { UserSettings } from './UserSettings';
import { SubscriptionSettings } from './SubscriptionSettings';

type TabType = 'user' | 'export' | 'subscription';

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState<TabType>('user');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="py-6">
          <div className="flex items-center space-x-2">
            <SettingsIcon className="h-6 w-6 text-blue-600" />
            <h1 className="text-2xl font-bold text-gray-900">Einstellungen</h1>
          </div>

          {/* Notifications */}
          <div className="mt-4 space-y-4">
            {error && (
              <div className="flex items-center gap-2 p-3 text-sm text-red-700 bg-red-50 rounded-md">
                <AlertCircle className="h-4 w-4 flex-shrink-0" />
                <p>{error}</p>
              </div>
            )}
            {success && (
              <div className="p-3 text-sm text-green-700 bg-green-50 rounded-md">
                <p>{success}</p>
              </div>
            )}
          </div>
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-lg shadow">
          {/* Tabs */}
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px">
              <button
                onClick={() => setActiveTab('user')}
                className={`py-4 px-6 font-medium text-sm border-b-2 ${
                  activeTab === 'user'
                    ? 'border-blue-600 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                Nutzerdaten
              </button>
              <button
                onClick={() => setActiveTab('subscription')}
                className={`py-4 px-6 font-medium text-sm border-b-2 ${
                  activeTab === 'subscription'
                    ? 'border-blue-600 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                Abonnement
              </button>
              <button
                onClick={() => setActiveTab('export')}
                className={`py-4 px-6 font-medium text-sm border-b-2 ${
                  activeTab === 'export'
                    ? 'border-blue-600 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                Export
              </button>
            </nav>
          </div>

          {/* Tab Content */}
          <div className="p-6">
            {activeTab === 'user' && (
              <UserSettings onError={setError} onSuccess={setSuccess} />
            )}
            {activeTab === 'subscription' && (
              <SubscriptionSettings onError={setError} onSuccess={setSuccess} />
            )}
            {activeTab === 'export' && (
              <div className="text-gray-500 text-center py-4">
                Export-Funktionen werden in Kürze verfügbar sein
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
