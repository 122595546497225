import React, { useState, useEffect } from 'react';
import { Damage } from '../../../types/damage';

interface EditDamageModalProps {
  damage: Damage;
  onClose: () => void;
  onSave: (updatedDamage: Damage) => void;
}

const EditDamageModal: React.FC<EditDamageModalProps> = ({
  damage,
  onClose,
  onSave,
}) => {
  const [title, setTitle] = useState(damage.title);
  const [description, setDescription] = useState(damage.description);

  useEffect(() => {
    // If there's no title and the description contains AI analysis
    if (!damage.title && description.includes('--- KI-Analyse ---')) {
      // Extract the first two lines after the AI Analysis marker
      const aiSection = description.split('--- KI-Analyse ---')[1].trim();
      const firstTwoLines = aiSection.split('\n').slice(0, 2);

      // Combine manufacturer and component into title
      if (firstTwoLines.length >= 2) {
        const newTitle = firstTwoLines.join(' - ').trim();
        setTitle(newTitle);
      }
    }
  }, [damage.title, description]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Only pass the fields that are actually being updated
    const updatedFields: Partial<Damage> = {
      title,
      description,
    };

    onSave({
      ...damage,
      ...updatedFields,
    });
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">Schaden bearbeiten</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Titel
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Beschreibung
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              rows={3}
              required
            ></textarea>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
            >
              Abbrechen
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Speichern
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDamageModal;
