import React, { useState, useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';
import { Process } from '../../types/process';
import { Damage } from '../../types/damage';
import { Workshop } from '../../types/workshop';
import { databases } from '../../utils/appwrite';
import { Download, Loader2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { ProcessPDFDocument } from './ProcessPDFDocument';
import { useBikeStore } from '../../stores/useBikeStore';
import { createMergedPDF } from '../../utils/pdf-merger';

interface PDFGeneratorButtonProps {
  process: Process;
  damages: Damage[];
  className?: string;
}

const PDFGeneratorButton: React.FC<PDFGeneratorButtonProps> = ({
  process,
  damages,
  className = ''
}) => {
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [workshop, setWorkshop] = useState<Workshop | null>(null);
  const { name } = useAuth();
  const { bike, fetchBike } = useBikeStore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch workshop data
        const workshopResponse = await databases.listDocuments(
          '67197d230036564bfc99',
          'workshop'
        );

        if (workshopResponse.documents.length > 0) {
          const workshopData = workshopResponse.documents[0];
          const formattedWorkshop: Workshop = {
            $id: workshopData.$id,
            name: workshopData.name,
            streetAddress: workshopData.streetAddress,
            postalCode: workshopData.postalCode,
            city: workshopData.city,
            phoneNumber: workshopData.phoneNumber,
            faxNumber: workshopData.faxNumber || '',
            email: workshopData.email || '',
            website: workshopData.website || '',
            bankName: workshopData.bankName || '',
            bankCode: workshopData.bankCode || '',
            accountNumber: workshopData.accountNumber || '',
            iban: workshopData.iban || '',
            bic: workshopData.bic || '',
            taxId: workshopData.taxId || '',
            vatId: workshopData.vatId || '',
            courtLocation: workshopData.courtLocation || '',
            registrationNumber: workshopData.registrationNumber || '',
            managers: Array.isArray(workshopData.managers)
              ? workshopData.managers
              : [],
            logo: workshopData.logo,
          };
          setWorkshop(formattedWorkshop);
        }

        await fetchBike(process.$id);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Fehler beim Laden der Daten');
      }
    };

    fetchData();
  }, [process.$id, fetchBike]);

  const generatePDF = async () => {
    if (!workshop || !bike) {
      setError('Daten nicht vollständig verfügbar');
      return;
    }

    setIsPdfGenerating(true);
    setError(null);

    try {
      const pdfComponent = (
        <ProcessPDFDocument
          username={name}
          process={process}
          damages={damages}
          workshop={workshop}
          bike={bike}
        />
      );

      const mergedPdfBlob = await createMergedPDF(
        pdfComponent,
        process.quoteFileId
      );

      // Create download link
      const url = URL.createObjectURL(mergedPdfBlob);
      window.open(url, '_blank');
      const link = document.createElement('a');
      link.href = url;
      link.download = `Schadensgutachten_${process.serialnumber}_${new Date().toISOString().split('T')[0]}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error generating PDF:', err);
      setError(
        'Fehler beim Generieren des PDFs. Bitte versuchen Sie es erneut.'
      );
    } finally {
      setIsPdfGenerating(false);
    }
  };

  const buttonClassName = `
    w-full inline-flex items-center justify-center px-4 py-2 lg:py-1 rounded-md
    ${isPdfGenerating ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'} 
    text-white transition-colors duration-200
    ${className}
  `.trim();

  return (
    <div className="relative">
      <button
        onClick={generatePDF}
        disabled={isPdfGenerating}
        className={buttonClassName}
      >
        {isPdfGenerating ? (
          <>
            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            PDF wird generiert...
          </>
        ) : (
          <>
            <Download className="w-4 h-4 mr-2" />
            PDF Exportieren
          </>
        )}
      </button>
      {error && (
        <span className="text-sm text-red-600 mt-1 block">{error}</span>
      )}
    </div>
  );
};

export default PDFGeneratorButton;
