import React from 'react';
import { Document, Page, View } from '@react-pdf/renderer';
import { Process } from '../../types/process';
import { Header } from './Header';
import { BikeInfo } from './BikeInfo';
import { Overview } from './DocumentOverview';
import { DamageReport } from './DamageReport';
import { Footer } from './Footer';
import { CoverPage } from './CoverPage';
import { styles } from './styles';
import { Workshop } from '../../types/workshop';
import { Damage } from '../../types/damage';
import { Bike } from '../../types/bike';

interface ProcessPDFDocumentProps {
  username: string | null;
  process: Process;
  damages: Damage[];
  workshop: Workshop;
  bike: Bike;
}

export const ProcessPDFDocument: React.FC<ProcessPDFDocumentProps> = ({
  username,
  process,
  damages,
  workshop,
  bike
}) => {
  const pages = [
    // Cover Page
    <Page key="cover" size="A4" style={styles.page}>
      <CoverPage process={process} workshop={workshop} bike={bike} />
    </Page>,

    // Overview Page with Bike Info
    <Page key="overview" size="A4" style={styles.page}>
      <Header
        processNumber={process.serialnumber}
        username={username || ""}
        workshop={workshop}
        bikeNumber={bike.frameNumber || ""}
      />
      <View style={styles.contentPage}>
        <BikeInfo bike={bike} />
        <Overview damages={damages} process={process} />
      </View>
      <Footer workshop={workshop} />
    </Page>,

    // Individual Damage Pages
    ...damages.map((damage, index) => (
      <Page key={`damage-${index}`} size="A4" style={styles.page}>
        <Header
          processNumber={process.serialnumber}
          username={username || ""}
          workshop={workshop}
          bikeNumber={bike.frameNumber || ""}
        />
        <View style={styles.contentPage}>
          <DamageReport damage={damage} />
        </View>
        <Footer workshop={workshop} />
      </Page>
    ))
  ];

  return <Document>{pages}</Document>;
};
