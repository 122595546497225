// src/components/settings/UserSettings.tsx
import { useState } from 'react';
import { Functions } from 'appwrite';
import { client } from '../../utils/appwrite';
import { Save } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface UserSettingsProps {
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
}

export const UserSettings: React.FC<UserSettingsProps> = ({
  onError,
  onSuccess,
}) => {
  const { userId } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const functions = new Functions(client);

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      onError('Die Passwörter stimmen nicht überein');
      return;
    }

    setLoading(true);

    try {
      const response = await functions.createExecution(
        '6723f9e000315399705a',
        JSON.stringify({
          action: 'changePassword',
          userId: userId,
          oldPassword: currentPassword,
          newPassword: newPassword,
        })
      );

      const result = JSON.parse(response.responseBody);

      if (result.success) {
        onSuccess('Passwort wurde erfolgreich geändert');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        onError(result.message || 'Passwort konnte nicht geändert werden');
      }
    } catch (error) {
      console.error('Password change error:', error);
      onError('Passwort konnte nicht geändert werden');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h2 className="text-lg font-semibold mb-4">Passwort ändern</h2>
        <form onSubmit={handlePasswordChange} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Aktuelles Passwort
            </label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full p-2 border rounded-md"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Neues Passwort
            </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-2 border rounded-md"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Neues Passwort bestätigen
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full p-2 border rounded-md"
              required
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className="flex items-center justify-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            <Save className="h-4 w-4" />
            <span>{loading ? 'Wird gespeichert...' : 'Passwort ändern'}</span>
          </button>
        </form>
      </div>
    </div>
  );
};
